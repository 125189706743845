import { Component, Fragment } from "react";
import {
  createTheme,
  MuiThemeProvider,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
  ListItemText,
  ListItemIcon,
  Grid,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { YieldApi } from "../../../api/yield";
import { toast } from "react-toastify";
import { diff } from "deep-object-diff";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Popup from "../../../reusable-components/popup/popup";
export interface IEditRatePlanPanelProps {
  isNew: boolean;
  ratePlan: any;
  hotel: any;
  onclose: () => void;
  onUpdate: (rp: any) => void;
  unEditableRP: boolean;
  counter: number;
}

const classes = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface State {
  isCreateRpDialogOpened: boolean;
  rateplanData: any;
  validateRRformula: boolean;
  validateRRformulaMsg: any;
  validateRPlanId: boolean;
  validateRPlanIdMsg: any;
  validateSetRate: boolean;
  validateSetRateMsg: any;
  rtSelected: any;
  rtMinMaxValue: any;
}

const theme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        marginLeft: 25,
        width: 230,
      },
    },
    MuiCard: {
      root: {
        width: "30%",
        float: "left",
        marginLeft: "10px",
        marginTop: "10px",
        fontSize: "1em",
      },
    },
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
  },
});
class EditRatePlanPanel extends Component<IEditRatePlanPanelProps> {
  readonly state: State = {
    rateplanData: {
      isManual: false,
      isOTAOnly: false,
      isOWSOnly: false,
      isReward: false,
      isGDSOnly: false,
      isCorp: false,
      id: "",
      longDescription: "",
      shortDescription: "",
      selectSpecificModeOfSale: "All",
    },
    validateRRformula: false,
    validateRRformulaMsg: "",
    validateRPlanId: false,
    validateRPlanIdMsg: "",
    validateSetRate: false,
    validateSetRateMsg: "",
    rtSelected: false,
    rtMinMaxValue: {},
    isCreateRpDialogOpened: false,
  };

  constructor(props: any) {
    super(props);
    this.state.rateplanData = this.props.ratePlan;
  }

  componentDidMount(): void {
    const { rateplanData } = this.state;

    if (this.state.rateplanData.isOTAOnly === true) {
      this.setState({
        rateplanData: {
          ...rateplanData,
          selectSpecificModeOfSale: "OTA",
        },
      });
      return;
    }

    if (this.state.rateplanData.isOWSOnly === true) {
      this.setState({
        rateplanData: {
          ...rateplanData,
          selectSpecificModeOfSale: "OWS",
        },
      });
      return;
    }

    if (this.state.rateplanData.isGDSOnly === true) {
      this.setState({
        rateplanData: {
          ...rateplanData,
          selectSpecificModeOfSale: "GDS",
        },
      });
      return;
    }

    if (this.state.rateplanData.isCorp === true) {
      this.setState({
        rateplanData: {
          ...rateplanData,
          selectSpecificModeOfSale: "Corp",
        },
      });
      return;
    }

    if (this.state.rateplanData.isRewards === true) {
      this.setState({
        rateplanData: {
          ...rateplanData,
          selectSpecificModeOfSale: "Rewards",
        },
      });
      return;
    }
  }

  handleSelect = () => {
    return this.state.rateplanData.selectSpecificModeOfSale;
  };

  openCreateRpDialog = () => {
    this.setState({
      isCreateRpDialogOpened: true,
    });
  };

  closeCreateRpDialog = () => {
    this.setState({
      isCreateRpDialogOpened: false,
    });
  };

  defaultChipValues = (
    chipAapplies: Array<String> | undefined,
    rtOptions: Array<{ label: String; value: String }>,
  ) => {
    const defaultValuesApplied: Array<{ label: String; value: String }> = [];
    if (chipAapplies !== undefined) {
      chipAapplies.forEach((roomIdApplied: String) => {
        let found = rtOptions.find((t) => t.value === roomIdApplied);
        if (found) {
          defaultValuesApplied.push(found);
        }
      });
    }
    return defaultValuesApplied.map((data) => data.label);
  };

  minMaxValue = (value: any) => {
    let roomtypes = this.props.hotel.getRoomTypes();
    let selectedRoomTypes = value;
    let selRoomTypesCount = selectedRoomTypes.length;
    let loopMatchVal = 0;
    for (let i = 0; i < selectedRoomTypes.length; i++) {
      let roomType = selectedRoomTypes[i];
      for (let j = 0; j < roomtypes.length; j++) {
        if (roomType == roomtypes[j].id) {
          let minRate = Number(roomtypes[j].minRate);
          let maxRate = Number(roomtypes[j].maxRate);
          if (
            minRate <= Number(this.state.rateplanData.defaultRate) &&
            Number(this.state.rateplanData.defaultRate) <= maxRate
          ) {
            loopMatchVal++;
          }
        }
      }
    }
    console.log("loopMatchVal", loopMatchVal, selRoomTypesCount);
    if (loopMatchVal > 0 && selRoomTypesCount == loopMatchVal) {
      return true;
    } else {
      return false;
    }
  };
  minMaxBoolean = (setRate: number) => {
    let roomtypes = this.props.hotel.getRoomTypes();
    let selectedRoomTypes = this.state.rateplanData.applies;
    let selRoomTypesCount = selectedRoomTypes.length;
    let loopMatchVal = 0;
    for (let i = 0; i < selectedRoomTypes.length; i++) {
      let roomType = selectedRoomTypes[i];
      for (let j = 0; j < roomtypes.length; j++) {
        if (roomType == roomtypes[j].id) {
          let minRate = Number(roomtypes[j].minRate);
          let maxRate = Number(roomtypes[j].maxRate);
          if (minRate <= setRate && setRate <= maxRate) {
            loopMatchVal++;
          }
        }
      }
    }
    if (loopMatchVal > 0 && selRoomTypesCount == loopMatchVal) {
      return true;
    } else {
      return false;
    }
  };
  handleChange = (event: any) => {
    const { rateplanData } = this.state;
    let fieldName = event.target.name;
    let value = event.target.value || event.target.checked;
    let v: any;
    // let rateplanData = this.state.rateplanData;
    let dateValue = "";
    let autocloseOut = rateplanData.autoCloseOut;
    let manualVariance = rateplanData.ManualRateVariance;
    let DOWCloseOut = rateplanData.DOWCloseOut;
    let defaultRate =
      typeof rateplanData.defaultRate != "undefined"
        ? rateplanData.defaultRate
        : "";
    console.log("defaultRate=>", defaultRate);
    let index = 0;
    let rtOptions = this.props.hotel.getRoomTypes().map((rt: any) => {
      return { label: rt.shortDescription, value: rt.id };
    });

    let AddOrRemoveFromDOWCloseOut = (isAdd: boolean, value: any) => {
      if (isAdd) {
        DOWCloseOut.days.push(value);
        return;
      }
      index = DOWCloseOut.days.indexOf(value);
      DOWCloseOut.days.splice(index, 1);
    };

    switch (fieldName) {
      case "selectedRT":
        fieldName = "applies";

        const { rateplanData } = this.state;

        console.log("value", value);

        if (value[value.length - 1] === "all") {
          const options = rtOptions.map((data: any) => data.value);

          this.setState({
            rateplanData: {
              ...rateplanData,
              applies:
                this.state.rateplanData.applies.length === options.length
                  ? []
                  : options,
            },
          });

          return;
        }

        if (this.minMaxValue(value)) {
          this.setState({ validateSetRate: false });
          this.setState({ validateSetRateMsg: "" });
        } else {
          this.setState({ validateSetRate: true });
          this.setState({
            validateSetRateMsg:
              "Rate lower than the room type’s minimum rate and greater than the room type’s maximum rate not allowed.",
          });
        }

        v = value;

        break;
      case "DOWCloseOut":
        if (value) {
          DOWCloseOut = { days: [] };
        } else {
          DOWCloseOut = false;
        }
        break;
      case "autoCloseOut":
        if (value) {
          autocloseOut = {
            openStart: moment().format("YYYY-MM-DD"),
            openEnd: moment().format("YYYY-MM-DD"),
          };
        } else {
          autocloseOut = false;
        }
        break;

      case "ManualRateVariance":
        if (value) {
          manualVariance = [0, 0, 0, 0, 0, 0, 0];
          this.setState({ validateSetRate: false });
        } else {
          manualVariance = false;
        }
        break;
      case "autoCloseOutStart":
        dateValue = moment(value).format("YYYY-MM-DD");
        autocloseOut.openStart = dateValue;
        v = autocloseOut;
        fieldName = "autoCloseOut";
        console.log(dateValue, value, "start");
        break;
      case "autoCloseOutEnd":
        dateValue = moment(value).format("YYYY-MM-DD");
        autocloseOut.openEnd = dateValue;
        v = autocloseOut;
        fieldName = "autoCloseOut";
        console.log(dateValue, value, "end");
        break;
      case "ManualRateVarianceMon":
        manualVariance[1] = value || "";
        break;
      case "ManualRateVarianceTue":
        manualVariance[2] = value || "";
        break;
      case "ManualRateVarianceWed":
        manualVariance[3] = value || "";
        break;
      case "ManualRateVarianceThu":
        manualVariance[4] = value || "";
        break;
      case "ManualRateVarianceFri":
        manualVariance[5] = value || "";
        break;
      case "ManualRateVarianceSat":
        manualVariance[6] = value || "";
        break;
      case "ManualRateVarianceSun":
        manualVariance[0] = value || "";
        break;
      case "DOWCloseOutMon":
        AddOrRemoveFromDOWCloseOut(value, 1);

        break;
      case "DOWCloseOutTue":
        AddOrRemoveFromDOWCloseOut(value, 2);
        break;
      case "DOWCloseOutWed":
        AddOrRemoveFromDOWCloseOut(value, 3);
        break;
      case "DOWCloseOutThu":
        AddOrRemoveFromDOWCloseOut(value, 4);
        break;
      case "DOWCloseOutFri":
        AddOrRemoveFromDOWCloseOut(value, 5);
        break;
      case "DOWCloseOutSat":
        AddOrRemoveFromDOWCloseOut(value, 6);
        break;
      case "DOWCloseOuSun":
        AddOrRemoveFromDOWCloseOut(value, 0);
        break;
      case "defaultRate":
        if (value == "") {
          defaultRate = "";
        } else {
          defaultRate = value;
        }
        if (value && Number(value) < 0) {
          this.setState({ validateSetRate: true });
          this.setState({ validateSetRateMsg: "Negative number not allowed." });
        } else if (value == "") {
          this.setState({ validateSetRate: true });
          this.setState({ validateSetRateMsg: "Please set rate." });
        } else {
          if (this.state.rateplanData.applies.length > 0) {
            if (this.minMaxBoolean(Number(value))) {
              this.setState({ validateSetRate: false });
              this.setState({ validateSetRateMsg: "" });
            } else {
              this.setState({ validateSetRate: true });
              this.setState({
                validateSetRateMsg:
                  "Rate lower than the room type’s minimum rate and greater than the room type’s maximum rate not allowed.",
              });
            }
          } else {
            this.setState({ validateSetRate: false });
            this.setState({ validateSetRateMsg: "" });
          }
        }
        break;
      default:
        v = value || "";
    }
    rateplanData[fieldName] = v;
    rateplanData.shortDescription;
    // rateplanData.selectSpecificModeOfSale;
    rateplanData.ManualRateVariance = manualVariance;
    rateplanData.DOWCloseOut = DOWCloseOut;
    rateplanData.autoCloseOut = autocloseOut;
    rateplanData.defaultRate = defaultRate;
    if (rateplanData.isManual) {
      delete rateplanData.formula;
    } else {
      delete rateplanData.defaultRate;
    }
    this.setState({ rateplanData });

    if (this.state.rateplanData.selectSpecificModeOfSale === "OWS") {
      const { rateplanData } = this.state;
      this.setState({
        rateplanData: {
          ...rateplanData,
          isOTAOnly: false,
          isOWSOnly: true,
          isGDSOnly: false,
          isCorp: false,
          isReward: false,
        },
      });
      console.log(this.state.rateplanData.isOTAOnly);
      return;
    }
    if (this.state.rateplanData.selectSpecificModeOfSale === "OTA") {
      const { rateplanData } = this.state;
      this.setState({
        rateplanData: {
          ...rateplanData,
          isOTAOnly: true,
          isOWSOnly: false,
          isGDSOnly: false,
          isCorp: false,
          isReward: false,
        },
      });
      console.log(this.state.rateplanData.isOTAOnly);
      return;
    }
    if (this.state.rateplanData.selectSpecificModeOfSale === "GDS") {
      const { rateplanData } = this.state;
      this.setState({
        rateplanData: {
          ...rateplanData,

          isOTAOnly: false,
          isOWSOnly: false,
          isGDSOnly: true,
          isCorp: false,
          isReward: false,
        },
      });
      console.log(this.state.rateplanData.isOTAOnly);
      return;
    }
    if (this.state.rateplanData.selectSpecificModeOfSale === "Corp") {
      const { rateplanData } = this.state;
      this.setState({
        rateplanData: {
          ...rateplanData,

          isOTAOnly: false,
          isOWSOnly: false,
          isGDSOnly: false,
          isCorp: true,
          isReward: false,
        },
      });
      console.log(this.state.rateplanData.isOTAOnly);
      return;
    }
    if (this.state.rateplanData.selectSpecificModeOfSale === "Rewards") {
      const { rateplanData } = this.state;
      this.setState({
        rateplanData: {
          ...rateplanData,
          isReward: true,
        },
      });
      console.log(this.state.rateplanData.isOTAOnly);
      return;
    }
    console.log(this.state.rateplanData);
  };

  createRateplan = () => {
    let hotel = this.props.hotel;
    let rp = this.state.rateplanData;
    if (this.state.rateplanData.isManual) {
      if (this.state.rateplanData.applies) {
        if (this.minMaxValue(this.state.rateplanData.applies)) {
          this.setState({ validateSetRate: false });
          this.setState({ validateSetRateMsg: "" });
        } else {
          this.setState({ validateSetRate: true });
          this.setState({
            validateSetRateMsg:
              "Rate lower than the room type’s minimum rate and greater than the room type’s maximum rate not allowed.",
          });
          toast.error("Please check highlighted field(s)");
          return;
        }
      }
      if (
        this.state.rateplanData.defaultRate &&
        Number(this.state.rateplanData.defaultRate) < 0
      ) {
        this.setState({ validateSetRate: true });
        this.setState({ validateSetRateMsg: "Negative number not allowed." });
        toast.error("Please check highlighted field(s)");
        return;
      } else if (this.state.rateplanData.defaultRate == "") {
        this.setState({ validateSetRate: true });
        this.setState({ validateSetRateMsg: "Please set rate." });
        toast.error("Please check highlighted field(s)");
        return;
      } else {
        this.setState({ validateSetRate: false });
        this.setState({ validateSetRateMsg: "" });
      }
      if (
        this.state.rateplanData.defaultRate &&
        Number(this.state.rateplanData.defaultRate) < 0
      ) {
        this.setState({ validateSetRate: true });
        this.setState({ validateSetRateMsg: "Negative number not allowed." });
        toast.error("Please check highlighted field(s)");
        return;
      } else if (this.state.rateplanData.defaultRate == "") {
        this.setState({ validateSetRate: true });
        this.setState({ validateSetRateMsg: "Please set rate." });
        toast.error("Please check highlighted field(s)");
        return;
      } else {
        if (this.state.rateplanData.applies.length > 0) {
          if (this.minMaxBoolean(Number(this.state.rateplanData.defaultRate))) {
            this.setState({ validateSetRate: false });
            this.setState({ validateSetRateMsg: "" });
          } else {
            this.setState({ validateSetRate: true });
            this.setState({
              validateSetRateMsg:
                "Rate lower than the room type’s minimum rate and greater than the room type’s maximum rate not allowed.",
            });
            toast.error("Please check highlighted field(s)");
            return;
          }
        } else {
          this.setState({ validateSetRate: false });
          this.setState({ validateSetRateMsg: "" });
        }
      }
    }
    if (
      this.state.rateplanData.hasOwnProperty("formula") &&
      (!this.state.rateplanData.formula ||
        !this.state.rateplanData.formula.match(/^[a-zA-Z0-9()+\-*\/.]*$/) ||
        !(
          this.state.rateplanData.formula.slice(0, 2).toUpperCase() == "RR" ||
          this.state.rateplanData.formula.slice(0, 3).toUpperCase() == "(RR"
        ))
    ) {
      let firstTwoChars = this.state.rateplanData.formula.slice(0, 2);
      let firstThreeCharacter = this.state.rateplanData.formula.slice(0, 3);
      let FormulaPrefix = firstTwoChars.toUpperCase();
      let FormulaBracketPrefix = firstThreeCharacter.toUpperCase();
      let pattern = /^[a-zA-Z0-9()+\-*\/.]*$/;
      if (FormulaPrefix != "RR" && FormulaBracketPrefix != "(RR") {
        this.setState({ validateRRformula: true });
        this.setState({
          validateRRformulaMsg:
            "Rate Plan formula should start with RR or '(RR'.",
        });
      } else if (!pattern.test(this.state.rateplanData.formula)) {
        this.setState({ validateRRformula: true });
        this.setState({
          validateRRformulaMsg:
            "Only basic mathematical operation symbols, parentheses and decimal separator allowed.",
        });
      } else {
        this.setState({ validateRRformula: false });
        this.setState({ validateRRformulaMsg: "" });
      }
      toast.error("Please check highlighted field(s)");
      return;
    } else if (
      this.state.rateplanData.hasOwnProperty("id") &&
      (!this.state.rateplanData.id ||
        !this.state.rateplanData.id.match(/^[A-Za-z0-9@\-_%]+$/))
    ) {
      if (!this.state.rateplanData.id) {
        this.setState({ validateRPlanId: true });
        this.setState({ validateRPlanIdMsg: "Mandatory field" });
      } else if (!this.state.rateplanData.id.match(/^[A-Za-z0-9@\-_%]+$/)) {
        this.setState({ validateRPlanId: true });
        this.setState({
          validateRPlanIdMsg:
            "Only alphabets, numbers & the following special characters allowed: @  -  _ ",
        });
      } else {
        this.setState({ validateRPlanId: false });
        this.setState({ validateRPlanIdMsg: "" });
      }
      toast.error("Please check highlighted field(s)");
      return;
    } else {
      if (rp.isManual) {
        delete rp.formula;
      } else {
        delete rp.defaultRate;
      }
      this.setState({
        rateplanData: {
          ...rp,
          hotelId: hotel.hotelId,
        },
      });

      this.openCreateRpDialog();
    }
  };

  updateRateplan = () => {
    let hotel = this.props.hotel;
    let rp = this.state.rateplanData;
    if (this.state.rateplanData.isManual) {
      if (
        typeof this.state.rateplanData.ManualRateVariance == "undefined" ||
        this.state.rateplanData.ManualRateVariance === false
      ) {
        if (this.state.rateplanData.applies) {
          if (this.minMaxValue(this.state.rateplanData.applies)) {
            this.setState({ validateSetRate: false });
            this.setState({ validateSetRateMsg: "" });
          } else {
            if (this.state.rateplanData.defaultRate === "") {
              this.setState({ validateSetRate: true });
              this.setState({ validateSetRateMsg: "Please set rate." });
              toast.error("Please check highlighted field(s)");
              return;
            } else {
              this.setState({ validateSetRate: true });
              this.setState({
                validateSetRateMsg:
                  "Rate lower than the room type’s minimum rate and greater than the room type’s maximum rate not allowed .",
              });
              toast.error("Please check highlighted field(s)");
              return;
            }
          }
        }
        if (
          this.state.rateplanData.defaultRate &&
          Number(this.state.rateplanData.defaultRate) < 0
        ) {
          this.setState({ validateSetRate: true });
          this.setState({ validateSetRateMsg: "Negative number not allowed." });
          toast.error("Please check highlighted field(s)");
          return;
        } else if (this.state.rateplanData.defaultRate === "") {
          this.setState({ validateSetRate: true });
          this.setState({ validateSetRateMsg: "Please set rate." });
          toast.error("Please check highlighted field(s)");
          return;
        } else {
          if (this.state.rateplanData.applies.length > 0) {
            if (
              this.minMaxBoolean(Number(this.state.rateplanData.defaultRate))
            ) {
              this.setState({ validateSetRate: false });
              this.setState({ validateSetRateMsg: "" });
            } else {
              this.setState({ validateSetRate: true });
              this.setState({
                validateSetRateMsg:
                  "Rate lower than the room type’s minimum rate and greater than the room type’s maximum rate not allowed.",
              });
              toast("Please check highlighted field(s)", { type: "error" });
              return;
            }
          } else {
            this.setState({ validateSetRate: false });
            this.setState({ validateSetRateMsg: "" });
          }
        }
      }
    }
    if (
      this.state.rateplanData.hasOwnProperty("formula") &&
      (!this.state.rateplanData.formula ||
        !this.state.rateplanData.formula.match(/^[a-zA-Z0-9()+\-*\/.]*$/) ||
        !(
          this.state.rateplanData.formula.slice(0, 2).toUpperCase() === "RR" ||
          this.state.rateplanData.formula.slice(0, 3).toUpperCase() === "(RR"
        ))
    ) {
      let firstTwoChars = this.state.rateplanData.formula.slice(0, 2);
      let firstThreeCharacter = this.state.rateplanData.formula.slice(0, 3);
      let FormulaPrefix = firstTwoChars.toUpperCase();
      let FormulaBracketPrefix = firstThreeCharacter.toUpperCase();
      let pattern = /^[a-zA-Z0-9()+\-*\/.]*$/;
      if (FormulaPrefix != "RR" && FormulaBracketPrefix != "(RR") {
        this.setState({ validateRRformula: true });
        this.setState({
          validateRRformulaMsg:
            "Rate Plan formula should start with RR or '(RR'.",
        });
      } else if (!pattern.test(this.state.rateplanData.formula)) {
        this.setState({ validateRRformula: true });
        this.setState({
          validateRRformulaMsg:
            "Only basic mathematical operation symbols, parentheses and decimal separator allowed.",
        });
      } else {
        this.setState({ validateRRformula: false });
        this.setState({ validateRRformulaMsg: "" });
      }
      toast.error("Please check highlighted field(s)");
      return;
    } else if (
      this.state.rateplanData.hasOwnProperty("id") &&
      (!this.state.rateplanData.id ||
        !this.state.rateplanData.id.match(/^[A-Za-z0-9@\-_%]+$/))
    ) {
      if (!this.state.rateplanData.id) {
        this.setState({ validateRPlanId: true });
        this.setState({ validateRPlanIdMsg: "Mandatory field" });
      } else if (!this.state.rateplanData.id.match(/^[A-Za-z0-9@\-_%]+$/)) {
        this.setState({ validateRPlanId: true });
        this.setState({
          validateRPlanIdMsg:
            "Only alphabets, numbers & the following special characters allowed: @  -  _ ",
        });
      } else {
        this.setState({ validateRPlanId: false });
        this.setState({ validateRPlanIdMsg: "" });
      }
      toast.error("Please check highlighted field(s)");
      return;
    } else {
      let difs = diff(rp, JSON.parse(JSON.stringify(hotel.ratePlans[rp.id])));
      if (Object.keys(difs).length === 0) {
        toast.warn("No change detected");
        this.props.onclose();
        return;
      }

      this.setState({
        rateplanData: {
          ...rp,
          hotelId: hotel.hotelId,
        },
      });

      this.openCreateRpDialog();
    }
  };

  callWriteRatePlan = async (rp: any, isModification: boolean) => {
    let actionName = isModification ? "modification" : "creation";
    toast.promise(YieldApi.writeRateplan(rp), {
      pending: "Rate Plan " + actionName + " in progress...",
      success: "Rate Plan " + actionName + " completed",
      error: "Rate Plan " + actionName + " failed",
    });
  };

  handleChipDelete = (e: any, value: any) => {
    this.setState({
      rtSelected: this.state.rateplanData.applies.filter(
        (name: any) => name !== value,
      ),
    });

    console.log("rtSelect valur _____", value);
  };

  onBlurOfInputFields = (event: any) => {
    let fieldName = event.target.name;
    let value = event.target.value;
    switch (fieldName) {
      case "id":
        if (!value) {
          this.setState({ validateRPlanId: true });
          this.setState({ validateRPlanIdMsg: "Mandatory field" });
        } else if (!value.match(/^[A-Za-z0-9@\-_%]+$/)) {
          this.setState({ validateRPlanId: true });
          this.setState({
            validateRPlanIdMsg:
              "Only alphabets, numbers & the following special characters allowed: @  -  _ ",
          });
        } else {
          this.setState({ validateRPlanId: false });
          this.setState({ validateRPlanIdMsg: "" });
        }
        break;
      case "formula":
        if (!value) {
          this.setState({ validateRRformula: true });
          this.setState({ validateRRformulaMsg: "Mandatory field" });
        } else {
          let firstTwoChars = this.state.rateplanData.formula.slice(0, 2);
          let firstThreeCharacter = this.state.rateplanData.formula.slice(0, 3);
          let FormulaPrefix = firstTwoChars.toUpperCase();
          let FormulaBracketPrefix = firstThreeCharacter.toUpperCase();
          let pattern = /^[a-zA-Z0-9()+\-*\/.]*$/;

          if (FormulaPrefix != "RR" && FormulaBracketPrefix != "(RR") {
            this.setState({ validateRRformula: true });
            this.setState({
              validateRRformulaMsg:
                "Rate Plan formula should start with RR or '(RR'.",
            });
          } else if (!pattern.test(this.state.rateplanData.formula)) {
            this.setState({ validateRRformula: true });
            this.setState({
              validateRRformulaMsg:
                "Only basic mathematical operation symbols, parentheses and decimal separator allowed.",
            });
          } else {
            this.setState({ validateRRformula: false });
            this.setState({ validateRRformulaMsg: "" });
          }
        }
        break;
      default:
        this.setState({ validateRRformula: false });
        this.setState({ validateRRformula: "" });
    }
  };

  styles = () => {
    control: (styles: any) => ({ ...styles, width: 100 });
  };

  render() {
    const rtoptions = this.props.hotel.getRoomTypes().map((rt: any) => {
      return { label: rt.shortDescription, value: rt.id };
    });

    const rpData = this.state.rateplanData;

    let isAllSelected =
      this.state.rateplanData.applies.length === rtoptions.length;

    const options =
      this.props.hotel.meta.hasRewards === true
        ? [
            { value: "All", label: "All" },
            { value: "OTA", label: "OTA Only" },
            { value: "OWS", label: "OWS Only" },
            { value: "GDS", label: "GDS Only" },
            { value: "Corp", label: "Corp Only" },
            { value: "Rewards", label: "Rewards" },
          ]
        : [
            { value: "All", label: "All" },
            { value: "OTA", label: "OTA Only" },
            { value: "OWS", label: "OWS Only" },
            { value: "GDS", label: "GDS Only" },
            { value: "Corp", label: "Corp Only" },
          ];

    return (
      <MuiThemeProvider theme={theme}>
        <FormControl style={{ display: "inline-block", width: "100%" }}>
          {this.props.isNew && (
            <div style={{ marginLeft: 25, fontSize: 20 }}>
              <b>New Rate Plan</b>
            </div>
          )}
          {!this.props.isNew && (
            <div style={{ marginLeft: 25, fontSize: 20 }}>
              <b>Edit Rate Plan:</b>{" "}
              <span style={{ color: "#d93025" }}>
                {rpData.shortDescription}
              </span>{" "}
              ({rpData.id})
            </div>
          )}
          <br />
          <TextField
            id="outlined-basic"
            label="Rate Plan Name"
            variant="outlined"
            name="shortDescription"
            onChange={this.handleChange}
            value={rpData.shortDescription}
          />
          <TextField
            id="outlined-basic"
            label="ID"
            style={{
              backgroundColor:
                this.props.unEditableRP === true ? "#D3D3D3" : "null",
            }}
            variant="outlined"
            name="id"
            onChange={this.handleChange}
            value={rpData.id}
            inputProps={{
              readOnly: this.props.unEditableRP === true ? true : false,
            }}
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validateRPlanId}
            helperText={this.state.validateRPlanIdMsg}
          />

          <div style={{ display: "inline-block", marginLeft: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rpData.isManual}
                  onChange={this.handleChange}
                  name="isManual"
                  color="primary"
                />
              }
              label="Set Rate"
            />
            {rpData.isManual && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rpData.ManualRateVariance ? true : false}
                    onChange={this.handleChange}
                    name="ManualRateVariance"
                    color="primary"
                  />
                }
                label="DOW variance"
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={rpData.DOWCloseOut ? true : false}
                  onChange={this.handleChange}
                  name="DOWCloseOut"
                  color="primary"
                />
              }
              label="Day of week stop sell"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={rpData.autoCloseOut ? true : false}
                  onChange={this.handleChange}
                  name="autoCloseOut"
                  color="primary"
                />
              }
              label="Temporary"
            />
          </div>

          <br />
          <div style={{ display: "flex", marginTop: 20 }}>
            {!rpData.isManual && (
              <TextField
                name="formula"
                value={rpData.formula}
                onChange={this.handleChange}
                onBlur={this.onBlurOfInputFields.bind(this)}
                label="Formula"
                error={this.state.validateRRformula}
                helperText={this.state.validateRRformulaMsg}
                variant="outlined"
              />
            )}
            {rpData.isManual && !rpData.ManualRateVariance && (
              <TextField
                type="number"
                name="defaultRate"
                onChange={this.handleChange}
                defaultValue={rpData.defaultRate}
                label="Set Rate"
                variant="outlined"
                error={this.state.validateSetRate}
                helperText={this.state.validateSetRateMsg}
              />
            )}
            {rpData.isManual && rpData.ManualRateVariance && (
              <Fragment>
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceMon"
                  label="Mon"
                  variant="outlined"
                  value={rpData.ManualRateVariance[1]}
                />
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceTue"
                  label="Tue"
                  variant="outlined"
                  value={rpData.ManualRateVariance[2]}
                />
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceWed"
                  label="Wed"
                  variant="outlined"
                  value={rpData.ManualRateVariance[3]}
                />
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceThu"
                  label="Thu"
                  variant="outlined"
                  value={rpData.ManualRateVariance[4]}
                />
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceFri"
                  label="Fri"
                  variant="outlined"
                  value={rpData.ManualRateVariance[5]}
                />
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceSat"
                  label="Sat"
                  variant="outlined"
                  value={rpData.ManualRateVariance[6]}
                />
                <TextField
                  onChange={this.handleChange}
                  name="ManualRateVarianceSun"
                  label="Sun"
                  variant="outlined"
                  value={rpData.ManualRateVariance[0]}
                />
              </Fragment>
            )}
            <FormControl
              variant="outlined"
              style={{ width: "238px", marginLeft: "20px" }}
            >
              <InputLabel id="mutiple-select-label">Room Type</InputLabel>
              <Select
                labelId="mutiple-select-label"
                label="Room Type"
                name="selectedRT"
                multiple
                value={this.state.rateplanData.applies}
                onChange={this.handleChange}
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {(this.state.rateplanData.applies as string[])
                      .filter((value: any) => value !== "all")
                      .map((value: any) => {
                        const chip = rtoptions.find(
                          (item: any) => item.value == value,
                        );
                        const label =
                          typeof chip != "undefined" ? chip.label : "";
                        if (label) {
                          return (
                            <Chip
                              key={value}
                              label={label}
                              style={{ margin: "2px" }}
                            />
                          );
                        }
                      })}
                  </div>
                )}
                // MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        this.state.rateplanData.applies.length > 0 &&
                        this.state.rateplanData.applies.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {rtoptions.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          this.state.rateplanData.applies.indexOf(
                            option.value,
                          ) > -1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              style={{ width: "238px", marginLeft: "20px" }}
            >
              <TextField
                defaultValue={this.state.rateplanData.selectSpecificModeOfSale}
                fullWidth
                select
                label="Mode of sale"
                variant="outlined"
                name="selectSpecificModeOfSale"
                onChange={this.handleChange}
                onBlur={this.onBlurOfInputFields.bind(this)}
              >
                <option value="All">All</option>
                <option value="OWS">OWS Only</option>
                <option value="OTA">OTA Only</option>
                <option value="GDS">GDS Only</option>
                <option value="Corp">Corp Only</option>
                {this.props.hotel.meta.hasRewards === true && (
                  <option value="Rewards">Rewards</option>
                )}
              </TextField>
            </FormControl>
          </div>
          <div
            style={{
              marginTop: rpData.autoCloseOut || rpData.DOWCloseOut ? 20 : 0,
            }}
          >
            {rpData.autoCloseOut && (
              <Grid>
                <TextField
                  defaultValue={rpData.autoCloseOut.openStart}
                  // style={{ width: "100%" }}
                  variant="outlined"
                  id="outlined-basic"
                  // fullWidth
                  label="Start"
                  type="date"
                  onChange={(event: any) =>
                    this.handleChange({
                      target: {
                        name: "autoCloseOutStart",
                        value: event.target.value,
                      },
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: new Date().toISOString().slice(0, 10),
                  }}
                />
                <TextField
                  defaultValue={rpData.autoCloseOut.openEnd}
                  // style={{ width: "100%" }}
                  variant="outlined"
                  id="outlined-basic"
                  // fullWidth
                  label="End"
                  type="date"
                  onChange={(event: any) =>
                    this.handleChange({
                      target: {
                        name: "autoCloseOutEnd",
                        value: event.target.value,
                      },
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: rpData.autoCloseOut.openStart,
                  }}
                />
              </Grid>
            )}
            {rpData.DOWCloseOut && (
              <Fragment>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ marginLeft: 25 }}
                      checked={rpData.DOWCloseOut.days.includes(1)}
                      onChange={this.handleChange}
                      name="DOWCloseOutMon"
                      color="primary"
                    />
                  }
                  label="Mon"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rpData.DOWCloseOut.days.includes(2)}
                      onChange={this.handleChange}
                      name="DOWCloseOutTue"
                      color="primary"
                    />
                  }
                  label="Tue"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rpData.DOWCloseOut.days.includes(3)}
                      onChange={this.handleChange}
                      name="DOWCloseOutWed"
                      color="primary"
                    />
                  }
                  label="Wed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rpData.DOWCloseOut.days.includes(4)}
                      onChange={this.handleChange}
                      name="DOWCloseOutThu"
                      color="primary"
                    />
                  }
                  label="Thu"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rpData.DOWCloseOut.days.includes(5)}
                      onChange={this.handleChange}
                      name="DOWCloseOutFri"
                      color="primary"
                    />
                  }
                  label="Fri"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rpData.DOWCloseOut.days.includes(6)}
                      onChange={this.handleChange}
                      name="DOWCloseOutSat"
                      color="primary"
                    />
                  }
                  label="Sat"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rpData.DOWCloseOut.days.includes(0)}
                      onChange={this.handleChange}
                      name="DOWCloseOutSun"
                      color="primary"
                    />
                  }
                  label="Sun"
                />
              </Fragment>
            )}
          </div>
          <br />

          <br />
          <div style={{ float: "right" }}>
            <Button
              aria-label="cancel"
              onClick={this.props.onclose}
              color="secondary"
              variant="contained"
              style={{
                marginRight: "10px",
                marginBottom: "10px",
                backgroundColor: "#808080",
                color: "#ffffff",
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>
            <Button
              aria-label="create"
              onClick={() =>
                this.props.isNew ? this.createRateplan() : this.updateRateplan()
              }
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#ce0e29",
                color: "#ffffff",
                marginRight: "10px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
            >
              Save
            </Button>
          </div>
        </FormControl>

        <Popup
          isPopupOpen={this.state.isCreateRpDialogOpened}
          title={`${this.props.isNew ? "Create" : "Edit"} ${rpData.shortDescription}`}
          content={[
            `Upon ${this.props.isNew ? "creation" : "modification"} of a rate
              plan, base rate for the next 2 years will be set to unpublished.
              Please review the adjustments on the yield sheet and proceed to
              publish them to propagate the changes to the channel manager.`,
          ]}
          action={() => {
            let rp = this.state.rateplanData;
            this.props.isNew
              ? this.callWriteRatePlan(rp, false)
              : this.callWriteRatePlan(rp, true);
            this.closeCreateRpDialog();
          }}
          actionName={`${this.props.isNew ? "Create" : "Edit"} Rate Plan`}
          cancelAction={this.closeCreateRpDialog}
          cancelName="Cancel"
        />
      </MuiThemeProvider>
    );
  }
}

export default EditRatePlanPanel;
