const permissions = (userInfo: any) => {
  try {
    return JSON.parse(userInfo.attributes["custom:permissions"]);
  } catch (err) {
    console.error(err);
    return JSON.parse(
      userInfo.signInUserSession.idToken.payload["custom:permissions"],
    );
  }
};

const monthlyRateReminder = (userInfo: any) => {
  try {
    console.log(
      "monthly prop",
      userInfo.attributes["custom:monthlyRateReminder"],
    );

    return userInfo.attributes["custom:monthlyRateReminder"];
  } catch (err) {
    console.error(err);
    return userInfo.signInUserSession.idToken.payload[
      "custom:monthlyRateReminder"
    ];
  }
};

const username = (userInfo: any) => {
  try {
    return userInfo.attributes.email;
  } catch (err) {
    console.error(err);
    return userInfo.signInUserSession.idToken.payload["email"];
  }
};

const zwtToken = (userInfo: any) => {
  try {
    return userInfo.signInUserSession.accessToken.jwtToken;
  } catch (err) {
    console.error(err);
    return;
  }
};

const utils = {
  permissions: permissions,
  username: username,
  monthlyRateReminder: monthlyRateReminder,
  zwtToken: zwtToken,
};

export { utils as AuthUtils };
