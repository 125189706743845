import { dom, GridSettings, renderers } from "handsontable";

import { rowType, Rowprop } from "./rowProp";
import { IYieldDay } from "../../models/yield.models";
import { gestRateCellContent } from "./cellformater/restrictions";
import { stat as gnStat, day as gnDay } from "gn-shared";

import moment from "moment";
import { fillCaption } from "./cellformater/rateCaptions";
import { YieldApi } from "../../api/yield";

let today = require("moment")();
today.hours(0);
var todayTS = today.valueOf();
let rowArray: number[] = [];

export function computeOtaValues(
  row: any,
  rowProps: any,
  compsetType: string,
  compset: any,
  col: any,
) {
  let r = rowProps.get(row);
  let compsetMessage = compset[col][r.hotelName]
    ? compset[col][r.hotelName][compsetType + "Message"]
    : undefined;
  let compsetV = compset[col][r.hotelName]
    ? compset[col][r.hotelName][compsetType]
    : undefined;
  let compsetMessageDisplay = "-";

  switch (compsetMessage) {
    case "general.missing":
      compsetMessageDisplay = "-";
      break;
    case "rates.soldout":
      compsetMessageDisplay = "Sold Out";
      break;
    case "rates.nobar":
      compsetMessageDisplay = "No BAR";
      break;
    case "rates.meal.missing.1":
      compsetMessageDisplay = "No BB";
      break;
    case "rates.meal.missing.5":
      compsetMessageDisplay = "No RO";
      break;
    case "rates.restrictionlos2":
      compsetMessageDisplay = "LOS2";
      break;
    case "rates.restrictionlos3":
      compsetMessageDisplay = "LOS3";
      break;
    case "rates.no_person.1":
      compsetMessageDisplay = "No 1 pax";
      break;
    case "rates.no_person.2":
      compsetMessageDisplay = "No 2 pax";
      break;
    case "rates.onlythirdparty":
      compsetMessageDisplay = "3rd party";
      break;
    default:
      compsetMessageDisplay = "Sold Out*";
      break;
  }

  let t =
    typeof compsetV != "undefined"
      ? compsetV == 0
        ? compsetMessageDisplay
        : compsetV
      : "-";

  return t;
}

export function colWidths(daysCt: number): number[] {
  var firstColWidth = 250;
  return [firstColWidth, ...new Array(daysCt).fill(100)];
}

export function applyCellPropertiesClassName(cellProperties: any): string {
  return (
    typeof cellProperties.className === "object"
      ? cellProperties.className.join(" ")
      : cellProperties.className
  )!;
}

function isReadonly(rowProp: any, hotel: any, permissions: any) {
  if (rowProp.isReadOnly || permissions.isReadOnly) {
    return true;
  }
  if (permissions.canEdit) {
    switch (rowProp.type) {
      case rowType.availability:
      case rowType.suggestedBaseRate:
      case rowType.actualBaseRate:
        return false;
      case rowType.leftToSell:
        return (!permissions.admin || permissions.admin.indexOf("*")) === -1
          ? true
          : hotel.isleftTosellReadOnly();
      case rowType.ratePlan:
        return rowProp.isManual ? false : true;
      case rowType.roomType:
        return false; //rowProps.isPublishingInventory()
      case rowType.actualOWSBaseRate:
        return false; //this one is overloadable
    }
  }
  return false;
}

function getClassToApply(
  col: number,
  rowProp: any,
  isBeforeToday: boolean,
  isEdited: boolean,
  isManualRPEdited: boolean,
  isWeekend: boolean,
  hotel: any,
  rowProps: Rowprop,
) {
  let classes = "no-wrap";
  let rowtype = rowProp.type;
  if (isWeekend) {
    classes += " weekend";
  }
  if (col <= 0) {
    classes += " black strong";
  }
  if (!rowProps.isPublishingInventory()) {
    classes += " htDimmed";
  }
  switch (rowtype) {
    case rowType.suggestedBaseRate:
      classes += " focus suggested-rate rate";
      break;
    case rowType.actualOWSBaseRate:
      classes = " inverse";
      break;
    case rowType.actualBaseRate:
      classes = " inverse";
      if (rowProp.hasManualRP) {
        if (isManualRPEdited) {
          classes += col === 0 ? "" : " manualinverse";
        } else {
          classes += col === 0 ? "" : " manual";
        }
      }

      break;
    case rowType.occupancy:
    case rowType.ota:
      classes += " neutralblue";
      break;
    case rowType.marketDemand:
      classes += " marketDemand";
      break;
    case rowType.ratePlan:
      classes += " rate";
      break;
    case rowType.roomType:
      if ("isPublishingInventory" in hotel.meta) {
        if (hotel.meta.isPublishingInventory === "true") {
          classes += " availibility";
        }
      }
      break;
    case rowType.pickup:
      classes += " pickup";
      break;
    case rowType.pickupFrom:
      classes += " pickupFrom";
      break;
    case rowType.leftToSell:
      classes += " leftToSell";
      break;
  }
  if (isEdited) {
    classes += " edited";
  }
  if (isBeforeToday) {
    classes += " disabled";
  }

  return classes;
}

export function cells(
  hotel: any,
  permissions: any,
  rowProps: Rowprop,
  sheet: IYieldDay[],
) {
  return (row?: number, col?: number, prop?: object): GridSettings => {
    if (
      [row, col, prop].some((attr) => attr === undefined) ||
      col! - 1 >= sheet.length
    ) {
      return {};
    }

    let rowProp = rowProps.get(row!);
    let day = sheet[col! - 1];
    let isWeekend = false;

    if (col! > 0) {
      let dow = moment(day.hrDate).day();
      if (dow === 5 || dow === 6) {
        isWeekend = true;
      }
    }
    const cellProperties: GridSettings = {};
    cellProperties.readOnly = true;
    if (col! > 0) {
      cellProperties.readOnly =
        isBeforeToday(day) || isReadonly(rowProp, hotel, permissions);
    }
    cellProperties.className = getClassToApply(
      col!,
      rowProp,
      isBeforeToday(day),
      isEdited(rowProp, day),
      isManualRPEdited(day),
      isWeekend,
      hotel,
      rowProps,
    );
    return cellProperties;
  };
}

function isManualRPEdited(day: IYieldDay): boolean {
  if (!day) {
    return false;
  }
  return Object.keys(gnDay.getUnpublishedManualRates(day)).length > 0;
}

function isEdited(rowProp: any, day: IYieldDay): boolean {
  if (!day) {
    return false;
  }
  switch (rowProp.type) {
    case rowType.ratePlan:
    case rowType.actualBaseRate:
    case rowType.actualOWSBaseRate:
      if (rowProp.isManual) {
        if (
          day.manualRates &&
          day.manualRates[rowProp.roomTypeId] &&
          day.manualRates[rowProp.roomTypeId]![rowProp.ratePlanId] &&
          day.manualRates[rowProp.roomTypeId]![rowProp.ratePlanId].stat ==
            gnStat.edited
        ) {
          return true;
        }
      }
      return day.stat === gnStat.edited;
    case rowType.roomType:
      return (
        (typeof day.availabilities[rowProp.roomTypeId] != "undefined"
          ? day.availabilities[rowProp.roomTypeId].stat
          : "-") == gnStat.edited
      );
    default:
      return false;
  }
}

function getAcceptButton(
  instance: any,
  row: number,
  col: number,
  value: any,
  day: any,
  forceRender: () => void,
) {
  const button = document.createElement("button");
  button.innerText = "✔";
  button.title = "Accept change";
  button.classList.add("btn", "btn-sm", "btn-success", "apply-btn");
  button.id = "acceptButton";
  button.addEventListener("click", (event) => {
    instance.setDataAtCell(row + 1, col, value);
    (event.target as HTMLButtonElement).disabled = true;
    event.stopPropagation();
    event.preventDefault();
  });

  return button;
}

function getDeclineButton(
  instance: any,
  row: number,
  col: number,
  value: any,
  day: any,
  forceRender: any,
) {
  const button = document.createElement("button");
  button.innerText = "✖";
  button.title = "Decline change";
  button.classList.add("btn", "btn-sm", "btn-danger", "apply-btn");
  button.addEventListener("click", (event) => {
    console.log(day);
    {
      let rateData = {
        hotelId: day.hotelId,
        start: day.hrDate,
        end: day.hrDate,
        suggestedRate: 0,
      };
      YieldApi.removeSuggestedDataAfterAcceptOrReject(rateData)
        .then((res: any) => {
          forceRender();
        })
        .catch((err: any) => {
          // toast("Error while matching suggested base rates to base rates", {
          //   type: "error",
          // });
          forceRender();
        });
    }
    // instance.setDataAtCell(row, col, value);
    (event.target as HTMLButtonElement).disabled = true;
    event.stopPropagation();
    event.preventDefault();
  });
  return button;
}

export function columns(
  displayRange: any,
  hotel: any,
  yieldSheet: any,
  permissions: any,
  sheet: IYieldDay[],
  compset: any[],
  compareOtaRate: any[],
  compareDemand: any[],
  rowProps: Rowprop,
  openSuggestionModalFunc: () => void,
  forceRender: () => void,
  computeLeftToSell: (e: any) => void,
) {
  if (sheet.length === 0) {
    return null;
  }
  const marketDemandPickup = rowProps.marketDemand;
  const renderer: renderers.Base = (
    instance,
    TD,
    row,
    col,
    prop,
    value,
    cellProperties,
  ) => {
    dom.empty(TD);

    let rowProp = rowProps.get(row);
    let rowtype = rowProp.type;
    if (col === 0) {
      filterRow(instance, yieldSheet, row, rowProp, value, displayRange);
      return fillCaption(
        row,
        value,
        sheet.length,
        prop,
        TD,
        cellProperties,
        rowProps,
        yieldSheet,
        hotel.suggestionParams.isEnabled,
        hotel,
        instance,
        permissions,
        compset,
        compareOtaRate,
        compareDemand,
        hotel.hotelOnboardDate,
        openSuggestionModalFunc,
        forceRender,
        computeLeftToSell,
      );
    }

    const day = sheet[col - 1];
    if (!day) {
      renderers.TextRenderer.apply(null, [
        instance,
        TD,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      return TD;
    }

    if (rowtype == rowType.rewards) {
      return getRewardsControl(instance, TD, value, col, row);
    }

    if (rowtype == rowType.suggestedBaseRate) {
      if (
        day.suggestedBaseRate !== undefined // change it
      ) {
        console.log("log data", gnStat, day);
        const div = document.createElement("div");
        div.innerText = `${value}`;
        //create the buttons

        let acceptButton = getAcceptButton(
          instance,
          row,
          col,
          value,
          day,
          forceRender,
        );
        let declineButton = getDeclineButton(
          instance,
          row,
          col,
          day.actualBaseRate,
          day,
          forceRender,
        );
        div.appendChild(acceptButton);
        div.appendChild(declineButton);
        //decline

        TD.appendChild(div);
        TD.className = applyCellPropertiesClassName(cellProperties);
        return TD;
      }
    }
    let formulaLabel;
    if (typeof hotel.cm.channelConfig.ows.label != "undefined") {
      if (hotel.cm.channelConfig.ows.label != "") {
        formulaLabel = hotel.cm.channelConfig.ows.label;
      } else {
        formulaLabel = "Default";
      }
    } else {
      formulaLabel = "Default";
    }
    if (
      rowtype === rowType.actualOWSBaseRate &&
      day.OWSoveride &&
      day.OWSoveride == "Manual"
    ) {
      let s = document.createElement("span");
      s.innerText = "" + day.actualOWSBaseRate;
      TD.append(s);
      let tooltip = document.createElement("span");
      tooltip.innerText = day.OWSoveride;
      tooltip.className = "overidetooltipManual";
      s.appendChild(tooltip);

      TD.className =
        day.stat == gnStat.edited ? "overridenManualEdited" : "overridenManual";
      return TD;
    } else if (
      rowtype === rowType.actualOWSBaseRate &&
      day.OWSoveride &&
      day.OWSoveride != formulaLabel &&
      day.OWSoveride != "Default"
    ) {
      let s = document.createElement("span");
      s.innerText = "" + day.actualOWSBaseRate;
      TD.append(s);
      let tooltip = document.createElement("span");
      tooltip.innerText = day.OWSoveride;
      tooltip.className = "overidetooltip";
      s.appendChild(tooltip);

      TD.className +=
        day.stat == gnStat.edited ? "overridenEdited" : "overriden";
      return TD;
    }
    if (rowtype == rowType.marketDemand) {
      let s = document.createElement("div");
      s.innerHTML = `${value}`;

      let compareDemandPercentage = Math.round(
        Number(compareDemand[col]) * 100,
      );

      let withoutPer = value.replace("%", "");

      let calculaedDemand =
        Number(withoutPer) - Number(compareDemandPercentage) + " %";

      let tooltip = document.createElement("span");

      if (
        calculaedDemand === "0 %" ||
        value == "-" ||
        compareDemand[col] == "-"
      ) {
        tooltip.innerHTML = "";
      } else if (Number(withoutPer) > compareDemandPercentage) {
        tooltip.innerHTML = `+${calculaedDemand}`;
        tooltip.className = "otaCompareDataMore";
      } else {
        tooltip.innerHTML = calculaedDemand;
        tooltip.className = "otaCompareDataLess";
      }

      s.appendChild(tooltip);
      TD.append(s);
      TD.className = "marketDemand";
      return TD;
    }

    if (rowtype === rowType.ota) {
      let r = rowProps.get(row);
      let otaRoomName = compset[col][r.hotelName]
        ? compset[col][r.hotelName][rowProps.defaultCompsetType + "RoomName"]
        : undefined;

      let compsetV = compset[col][r.hotelName]
        ? compset[col][r.hotelName][rowProps.defaultCompsetType]
        : undefined;

      let compareOtaRates = compareOtaRate[col][r.hotelName]
        ? compareOtaRate[col][r.hotelName][rowProps.defaultCompsetType]
        : undefined;

      let compareOtaRoomName = compareOtaRate[col][r.hotelName]
        ? compareOtaRate[col][r.hotelName][
            rowProps.defaultCompsetType + "RoomName"
          ]
        : undefined;

      let otaRoomNameAndRoomType = `${otaRoomName}`;

      let compareOtaRoomNameAndRoomType = `${compareOtaRoomName}`;

      let t = computeOtaValues(
        row,
        rowProps,
        rowProps.defaultCompsetType,
        compset,
        col,
      );
      let s = document.createElement("h6");
      s.id = `otarate_${row}_${col}`;
      s.className = "otaSpanClass";
      s.innerText = t;

      let ans = compsetV - compareOtaRates;

      TD.append(s);

      let tooltip = document.createElement("span");
      let flag = document.createElement("div");

      let hoverText = document.createElement("div");
      let compareRoomNameDiv = document.createElement("div");
      compareRoomNameDiv.className = "compareRoomNameDiv";
      let currentRoomNameElement = document.createElement("span");
      let prevRoomNameElement = document.createElement("span");
      let roomAndRateType = document.createElement("span");

      tooltip.innerHTML = ans.toString();
      hoverText.className = "prevTooltip";

      if (col == 1) {
        rowArray.push(row);
      }

      let lastCol = rowArray[hotel.ota.compSet.length - 1];
      let secondLast = rowArray[hotel.ota.compSet.length - 2];
      let thirdLast = rowArray[hotel.ota.compSet.length - 3];

      if (row == lastCol || row == secondLast || row == thirdLast) {
        compareRoomNameDiv.className = "compareRoomNameDiv";
        currentRoomNameElement.className = "upTooltip";
        prevRoomNameElement.className = "upTooltip";
        roomAndRateType.className = "singleRoomNameEx";
      } else {
        compareRoomNameDiv.className = "";
        currentRoomNameElement.className = "orignalTooltip";
        prevRoomNameElement.className = "orignalTooltip";
        roomAndRateType.className = "roomToolTip";
      }

      let compareDate = moment(marketDemandPickup).format("DD/MM");

      if (compsetV == 0 || compsetV === undefined) {
        currentRoomNameElement.innerText = "";
        prevRoomNameElement.innerText = "";
        roomAndRateType.innerHTML = "";
      } else if (compareOtaRates == 0 || compareOtaRates == undefined) {
        roomAndRateType.innerHTML = otaRoomName ? `${otaRoomName}` : "NA";
        currentRoomNameElement.innerText = "";
        prevRoomNameElement.innerText = "";
        // roomAndRateType.className = "roomToolTip";
      } else if (otaRoomNameAndRoomType !== compareOtaRoomNameAndRoomType) {
        flag.className = "otaRowFlag";
        flag.innerHTML = "&#9666";
        currentRoomNameElement.innerHTML = `&#8226; Today: ${compsetV} &nbsp&nbsp&nbsp - ${otaRoomNameAndRoomType}`;
        prevRoomNameElement.innerHTML = `&#8226; ${compareDate}: ${compareOtaRates} &nbsp&nbsp&nbsp - ${compareOtaRoomNameAndRoomType}`;
      } else {
        roomAndRateType.innerHTML = otaRoomName ? `${otaRoomName}` : "NA";
      }

      if (ans < 0) {
        tooltip.className = "otaCompareDataLess";
        tooltip.innerHTML = ans.toString();
      } else if (
        ans == 0 ||
        compareOtaRates == 0 ||
        compareOtaRates == undefined
      ) {
        tooltip.innerHTML = "";
      } else if (compsetV === undefined) {
        tooltip.innerHTML = "";
      } else {
        tooltip.className = "otaCompareDataMore";
        tooltip.innerHTML = `+${ans.toString()}`;
      }

      s.appendChild(tooltip);
      s.appendChild(flag);
      s.appendChild(hoverText);

      hoverText.appendChild(compareRoomNameDiv);
      compareRoomNameDiv.appendChild(currentRoomNameElement);
      compareRoomNameDiv.appendChild(prevRoomNameElement);
      hoverText.appendChild(roomAndRateType);

      TD.className = "otaRow";
      return TD;
    }

    let restrictionContent = gestRateCellContent(
      yieldSheet,
      rowProps,
      rowProp,
      day,
      value,
      cellProperties,
    );
    if (restrictionContent) {
      TD.className = applyCellPropertiesClassName(cellProperties);
      TD.appendChild(restrictionContent);
    } else {
      if (value && typeof value == "string" && value.indexOf(";") !== -1) {
        let t = value.split(";");
        value = t[0];
      }
      renderers.TextRenderer.apply(null, [
        instance,
        TD,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
    }

    return TD;
  };
  return (index: number | undefined) => ({ renderer });
}

export function getRowProp(appStore: any, hotelId: number): Rowprop {
  return appStore.meta.config.rowProps[hotelId];
}

export function getRooms(sheet: IYieldDay[]): string[] {
  if (!sheet.length) {
    return [];
  }
  const day = sheet[0];
  return Object.keys(day.derivedRates);
}

export function hasOWSBRRow(hotel: any) {
  return hotel.cm.channelConfig && hotel.cm.channelConfig.ows;
}

// REV-261
export function availabilityExceedsTotalRooms(
  totalRooms: string,
  value: number,
): boolean | null {
  return Number(totalRooms) ? Number(totalRooms) < value : null;
}

export function filterRow(
  instance: any,
  yieldSheet: any,
  row: number,
  rowProp: any,
  rowName: string,
  displayRange: any,
) {
  if (!rowName) {
    return;
  }
  if (rowProp.type != rowType.ratePlan) {
    return;
  }

  if (rowProp.autoCloseOut) {
    if (
      (displayRange.start &&
        displayRange.end &&
        rowProp.autoCloseOut.start > displayRange.end) ||
      rowProp.autoCloseOut.end < displayRange.start
    ) {
      yieldSheet.outOfRange.add(rowProp.ratePlanId);
      const plugin = instance.getPlugin("hiddenRows");
      plugin.hideRows([row]);
    }
  }

  if (yieldSheet.filtered && yieldSheet.filtered.includes(row)) {
    const plugin = instance.getPlugin("hiddenRows");
    plugin.hideRows([row]);
  }
  if (yieldSheet.unfiltered && yieldSheet.unfiltered.includes(row)) {
    const plugin = instance.getPlugin("hiddenRows");
    plugin.showRows([row]);
  }
}

export function isBeforeToday(day: IYieldDay) {
  if (!day) {
    return false;
  }
  if (todayTS > day.dateMidday) {
    return true;
  }
  return false;
}

function getRewardsControl(
  instance: any,
  TD: HTMLElement,
  value: any,
  col: number,
  row: number,
) {
  const button = document.createElement("input");
  button.type = "checkbox";
  if (value == "yes") {
    button.checked = true;
  }
  button.addEventListener("click", (event: MouseEvent) => {
    event.stopPropagation();
    let target = event.target as HTMLInputElement;
    instance.setDataAtCell(row, col, target.checked ? "yes" : "no");
  });
  const div = document.createElement("div");
  div.appendChild(button);
  TD.appendChild(div);
  return TD;
}
