import { Component } from "react";
import List from "@material-ui/core/List";
import InboxIcon from "@material-ui/icons/Inbox";
import PeopleIcon from "@material-ui/icons/People";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LocalHotelIcon from "@material-ui/icons/LocalHotel";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import {
  Drawer,
  createTheme,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
} from "@material-ui/core";
import { ConfigService } from "../services";
import { Outlet } from "react-router-dom";
import withRouter from "../withRouter";

const userManagementKey = "user";
const execSummarykey = "exec";
const manageRoomsKey = "rooms";
const manageHotelKey = "hotel";
const manageCompSetKey = "CompSet";

const drawerWidth = 269;

const theme: any = createTheme({
  overrides: {
    MuiDrawer: {
      root: {
        position: "relative",
        display: "flex",
        width: drawerWidth,
        flexShrink: 0,
      },
      paper: {
        top: "46px",
        width: drawerWidth,
      },
    },
    MuiListItem: {
      root: {
        "&$selected": { backgroundColor: "#fce8e6", color: "#d93025" },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: "bolder",
      },
    },
  },
});

interface State {
  currentSelection: string;
  hashChange: any;
}

interface Props {
  adminUser: string;
  router: any;
  activeHotelId: number;
}

class Settings extends Component<Props, State> {
  readonly state: State = {
    currentSelection: execSummarykey,
    hashChange: "",
  };

  componentDidMount() {
    let locat = window.location.pathname;
    this.setState({ hashChange: locat });

    let loc = this.props.router.location.pathname;
    if (loc == `/${this.props.activeHotelId}/settings/rooms`) {
      this.setState({ currentSelection: manageRoomsKey });
    }
    if (loc == `/${this.props.activeHotelId}/settings/executive-summary`) {
      this.setState({ currentSelection: execSummarykey });
    }
    if (loc == `/${this.props.activeHotelId}/settings/user`) {
      this.setState({ currentSelection: userManagementKey });
    }
    if (loc == `/${this.props.activeHotelId}/settings/hotel-config`) {
      this.setState({ currentSelection: manageHotelKey });
    }
    if (loc == `/${this.props.activeHotelId}/settings/competitive-set`) {
      this.setState({ currentSelection: manageCompSetKey });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      let loc = this.props.router.location.pathname;
      if (
        loc == `/${this.props.activeHotelId}/settings` ||
        loc == `/${this.props.activeHotelId}/settings/`
      ) {
        this.props.router.navigate(
          `/${this.props.activeHotelId}/settings/executive-summary`,
        );
      }
      if (loc == `/${this.props.activeHotelId}/settings/rooms`) {
        this.setState({ currentSelection: manageRoomsKey });
      }
      if (loc == `/${this.props.activeHotelId}/settings/executive-summary`) {
        this.setState({ currentSelection: execSummarykey });
      }
      if (loc == `/${this.props.activeHotelId}/settings/user`) {
        this.setState({ currentSelection: userManagementKey });
      }
      if (loc == `/${this.props.activeHotelId}/settings/hotel-config`) {
        this.setState({ currentSelection: manageHotelKey });
      }
      if (loc == `/${this.props.activeHotelId}/settings/competitive-set`) {
        this.setState({ currentSelection: manageCompSetKey });
      }
    }
  }
  updateglobalStoreHotel = async (hotel: any) => {
    ConfigService.revanista.hotel.updateConfig(hotel.hotelId, this.props);
  };

  selectItem(item: string, navRoute: string) {
    this.setState({ currentSelection: item });

    this.props.router.navigate(
      `/${this.props.activeHotelId}/settings/${navRoute}`,
    );
  }

  render() {
    let execSelected = this.state.currentSelection === execSummarykey;
    let userSelected = this.state.currentSelection === userManagementKey;
    let roomConfigSelected = this.state.currentSelection === manageRoomsKey;
    let hotelConfigSelected = this.state.currentSelection === manageHotelKey;
    let compSetConfigSelected =
      this.state.currentSelection === manageCompSetKey;

    return (
      <MuiThemeProvider theme={theme}>
        <div
          style={{
            maxWidth: "95%",
            display: "block",
            paddingLeft: "263px",
            paddingRight: "0px",
            marginLeft: "30px",
            marginRight: "10px",
          }}
        >
          <Drawer variant="permanent" anchor="left">
            <div />
            <Divider />
            <List>
              <ListItem
                button
                key={execSummarykey}
                selected={execSelected}
                onClick={() => {
                  this.selectItem(execSummarykey, "executive-summary");
                }}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"Executive Summary"} />
              </ListItem>
              <Divider />
              <ListItem
                button
                key={userManagementKey}
                selected={userSelected}
                onClick={() => {
                  this.selectItem(userManagementKey, "user");
                }}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={"Manage Users"} />
              </ListItem>
              <Divider />
              <ListItem
                button
                key={manageHotelKey}
                selected={hotelConfigSelected}
                onClick={() => {
                  this.selectItem(manageHotelKey, "hotel-config");
                }}
              >
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary={"Hotel Config"} />
              </ListItem>
              <Divider />
              <ListItem
                button
                key={manageRoomsKey}
                selected={roomConfigSelected}
                onClick={() => {
                  this.selectItem(manageRoomsKey, "rooms");
                }}
              >
                <ListItemIcon>
                  <LocalHotelIcon />
                </ListItemIcon>
                <ListItemText primary={"Rooms Config"} />
              </ListItem>
              <Divider />
              {this.props.adminUser[0] === "*" && (
                <ListItem
                  button
                  key={manageCompSetKey}
                  selected={compSetConfigSelected}
                  onClick={() => {
                    this.selectItem(manageCompSetKey, "competitive-set");
                  }}
                >
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Competitive Set Config"} />
                </ListItem>
              )}
            </List>
          </Drawer>
          <Outlet />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(Settings);
