import { AdminApi } from "../../../api/admin";
import { computeRowProps } from "../../yield-sheet/rowProp";

const revanista = {
  hotel: {
    configure: (currentUser: any, props: any) => {
      const { appStore } = props;
      appStore.requests.setAdmin("getHotelConfig", "fetching");
      AdminApi.getUsersHotelsMeta().then((response) => {
        let hotelTofetch: number[] = [];
        const payload = JSON.parse(response.Payload);
        payload.forEach((payload: any) => {
          hotelTofetch.push(payload.hotelId);
        });

        const pathname = window.location.pathname;
        const parts = pathname.split("/");
        const firstRoute = parseInt(parts[1]) || hotelTofetch[0];

        console.log("all hotel", firstRoute);

        AdminApi.getHotelConfig(firstRoute)
          .then(async (h: any) => {
            let hotels = new Map<number, any>();
            hotels.set(h.hotelId, h);
            appStore.meta.setConfig("hotels", hotels);
            let rowProps = new Map<number, any>();
            for (let id of hotels.keys()) {
              let h = hotels.get(id);
              var rowProp = await computeRowProps(h);
              rowProps.set(id, rowProp);
            }
            appStore.meta.setConfig("rowProps", rowProps);
            appStore.requests.setAdmin("getHotelConfig", "success");
            return payload;
          })
          .catch((err: any) => {
            appStore.meta.setConfig("hotels", {});
            appStore.requests.setAdmin("getHotelConfig", "error");
            throw err;
          });
      });
    },
    updateConfig: (hotelId: number, props: any) => {
      const { appStore } = props;
      AdminApi.getHotelConfig(hotelId)
        .then(async (h: any) => {
          let hs = appStore.meta.config.hotels;
          hs[hotelId] = h;
          appStore.meta.setConfig("hotels", hs);
          let r = appStore.meta.config.rowProps;
          var rowProp = await computeRowProps(h);
          r[hotelId] = rowProp;
          appStore.meta.setConfig("rowProps", r);
        })
        .catch((err: any) => {
          console.log(err);
        });
    },
  },
};

const ConfigService = {
  revanista: revanista,
};

export { ConfigService };
