import { API } from "aws-amplify";
import { IHotelConfig } from "../models/hotel.models";

export class AdminApi {
  static async getHotelConfig(hotelId: number): Promise<any> {
    return API.get("yield", `/hotel/${hotelId}`, null);
  }

  static async getUsersHotelsMeta(): Promise<any> {
    return API.get("backend", `/hotels`, null);
  }

  static async createHotelConfig(
    hotelId: number,
    body: IHotelConfig,
  ): Promise<IHotelConfig> {
    return API.post("backend", `/hotel/${hotelId}`, { body });
  }

  static async deleteHotelConfig(hotelId: number): Promise<IHotelConfig> {
    return API.del("backend", `/hotel/${hotelId}`, null);
  }
}
