import { IUserCreateRequest, monthlyPopup } from "./../models/api.models";
import { API } from "aws-amplify";

export class UserApi {
  static async createHotelUser(body: IUserCreateRequest) {
    return await API.post("user", `/${body.hotelIds[0]}`, { body });
  }

  static async deleteHotelUser(body: IUserCreateRequest) {
    return await API.del("user", `/${body.hotelIds[0]}`, {
      body,
    });
  }

  static async resetUserPassword(body: IUserCreateRequest) {
    return await API.post("user", `/${body.hotelIds[0]}/reset`, {
      body,
    });
  }

  static async listHotelUser(hotelId: number) {
    return await API.get("user", `/${hotelId}`, {});
  }

  static async getAttribute(userToken: string) {
    return await API.get("user", `/userAttribute/${userToken}`, {});
  }

  static async updateMonthlyPopupValue(body: monthlyPopup) {
    return await API.post("user", `/popupValue/${body.hotelIds}`, {
      body,
    });
  }

  static async remindMeLater(body: monthlyPopup) {
    return await API.post("user", `/remindMeLater/${body.hotelIds}`, {
      body,
    });
  }
}
