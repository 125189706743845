import { action, computed, decorate, observable, toJS } from "mobx";

class MetaStore {
  _rootStore: any;

  _user: any = {
    username: null,
    permissions: {
      admin: [],
      hotel: {},
    },
    monthlyRateReminder: null,
    hotels: [{ hotelId: -1, name: "Not available" }],
    zwtToken: null,
  };

  _config: any = {
    hotel: {},
    hotels: [],
  };

  constructor(rootStore: any) {
    this._rootStore = rootStore;
    this._config = {
      hotel: {},
    };
  }

  get user() {
    if (this._user) {
      return toJS(this._user);
    }
  }

  get config() {
    try {
      return toJS(this._config);
    } catch (err) {
      console.error(err);
    }
  }

  setConfig(target: string, value: any) {
    try {
      this._config[target] = value;
    } catch (err) {
      console.error(err);
    }
  }

  setUser(target: string, value: any) {
    try {
      this._user[target] = value;
    } catch (err) {
      console.error(err);
    }
  }
}

decorate(MetaStore, {
  _config: observable,
  config: computed,
  setConfig: action,
  _user: observable,
  user: computed,
  setUser: action,
});

export { MetaStore };
