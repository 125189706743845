import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Component, RefObject, createRef } from "react";
import List from "@material-ui/core/List";
import { YieldSheetService, ConfigService } from "../../services";
import { UserApi } from "../../../api/user";
import { hotel as gnHotel } from "gn-shared";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import ResetIcon from "@material-ui/icons/RotateLeft";
import { toast } from "react-toastify";
import {
  Container,
  TextField,
  Button,
  Divider,
  CardActions,
  Card,
  Typography,
  createTheme,
  MuiThemeProvider,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  IconButton,
  Tooltip,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import SectionTitle from "../../elements/sectionTitle";
import { CSSTransition } from "react-transition-group";
import { appStore } from "../../store";
import withRouter from "../../withRouter";
import handleEmailValidation from "../../utils/emailValidator";
import Popup from "../../../reusable-components/popup/popup";

const theme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        maxWidth: "350px",
        width: "30%",
        float: "left",
        margin: "10px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
      },
    },
    MuiTypography: {
      root: {
        float: "left",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          fontWeight: "bolder",
        },
      },
    },
  },
});

interface State {
  userList: any[];
  adminList: any[];
  isSubscribePanelOpened: boolean;
  isCreateUserPanelOpened: boolean;
  isCreateAdminPanelOpened: boolean;
  subscriptionList: string[];
  isUserDeleteDialogOpened: boolean;
  isAdminDeleteDialogOpened: boolean;
  selectedSubscriptionusers: string[];
  userTodelete: string;
  createUserData: any;
  createAdminData: any;
  isOverflowAdmin: boolean[];
  isOverflowUser: boolean[];
  isOverflowNoti: boolean[];
  isUserUpdated: boolean;
  isUpdated: boolean;
  isNotifyUpdated: boolean;
  updatingUser: string[];
  isUserEmailCorrect: boolean;
  userValidationMsg: string;
  isAdminEmailCorrect: boolean;
  adminValidationMsg: string;
  isLoading: boolean;
}

export interface IUserProps {
  activeHotelId: any;
  router: any;
}

class Users extends Component<IUserProps> {
  private mainDivRefsAdmin: RefObject<HTMLDivElement>[] = [];
  private mainDivRefsUser: RefObject<HTMLDivElement>[] = [];
  private mainDivRefsNoti: RefObject<HTMLDivElement>[];
  readonly state: State = {
    userList: [],
    adminList: [],
    subscriptionList: [],
    createUserData: {},
    createAdminData: {},
    userTodelete: "",
    isSubscribePanelOpened: false,
    isCreateUserPanelOpened: false,
    isCreateAdminPanelOpened: false,
    isUserDeleteDialogOpened: false,
    isAdminDeleteDialogOpened: false,
    selectedSubscriptionusers: [],
    isOverflowAdmin: [],
    isOverflowUser: [],
    isOverflowNoti: [],
    isUserUpdated: false,
    isUpdated: false,
    isNotifyUpdated: false,
    updatingUser: [],
    isAdminEmailCorrect: true,
    adminValidationMsg: "",
    isUserEmailCorrect: true,
    userValidationMsg: "",
    isLoading: true,
  };

  hotelIds: number[];
  hotelDisplayName: string;
  hotel: any;
  currentUser: string;
  userListHotel: any;
  users: any[];

  constructor(props: any) {
    super(props);
    this.hotel = gnHotel(appStore.meta.config.hotels[this.props.activeHotelId]);
    this.currentUser = appStore.meta._user.username;
    this.users = [];

    this.hotelIds = [this.hotel.hotelId];

    this.userListHotel = this.hotel;

    this.hotelDisplayName = this.hotel.info.name;
    this.state.subscriptionList = this.userListHotel.info.contactEmails;
    this.handleSubscriberSelection = this.handleSubscriberSelection.bind(this);

    this.fetchUser().then((users) => {
      this.mainDivRefsUser = users.users.map(() => createRef<HTMLDivElement>());
      this.state.isOverflowUser = users.users.map(() => false);

      this.mainDivRefsAdmin = users.admins.map(() =>
        createRef<HTMLDivElement>(),
      );
      this.state.isOverflowAdmin = users.admins.map(() => false);
    });

    this.mainDivRefsNoti = this.state.subscriptionList.map(() =>
      createRef<HTMLDivElement>(),
    );

    this.state.isOverflowNoti = this.state.subscriptionList.map(() => false);
  }

  setNewUserEmail = (e: any) => {
    this.setState({
      createUserData: {
        email: e.target.value.toLowerCase().replace(/\s+/g, ""),
        isAdmin: false,
      },
    });
    if (e.target.value) {
      this.setState({ isUserUpdated: true });
    }
    if (!e.target.value) {
      this.setState({ isUserUpdated: false });
    }
  };

  setNewAdminEmail = (e: any) => {
    this.setState({
      createAdminData: {
        email: e.target.value.toLowerCase().replace(/\s+/g, ""),
        isAdmin: true,
      },
    });
    if (e.target.value) {
      this.setState({ isUpdated: true });
    }
    if (!e.target.value) {
      this.setState({ isUpdated: false });
    }
  };

  toggleCreateUserPanel = () => {
    this.setState({
      isCreateUserPanelOpened: !this.state.isCreateUserPanelOpened,
      isUserEmailCorrect: true,
      userValidationMsg: "",
    });
  };

  toggleCreateAdminPanel = () => {
    this.setState({
      isCreateAdminPanelOpened: !this.state.isCreateAdminPanelOpened,
      isAdminEmailCorrect: true,
      adminValidationMsg: "",
    });
  };

  createAdmin = async () => {
    this.setState({ isUpdated: false });

    // checking if the field is empty ?

    if (!this.state.createAdminData.email) {
      this.setState({
        isAdminEmailCorrect: false,
        adminValidationMsg: "Mandatory fields",
      });
      toast.error("Please check highlighted field(s).");
      return;
    }

    const validateAdmin = handleEmailValidation(
      this.state.createAdminData.email,
    );

    // checking if field is not empty then is email valid ?

    if (validateAdmin === false) {
      this.setState({
        isAdminEmailCorrect: false,
        adminValidationMsg: "Invalid email address.",
      });
      toast.error("Please check highlighted field(s).");
      return;
    } else {
      this.setState({ isAdminEmailCorrect: true, adminValidationMsg: "" });

      await UserApi.createHotelUser({
        hotelIds: this.hotelIds,
        userEmail: this.state.createAdminData.email,
        isAdmin: this.state.createAdminData.isAdmin,
      })
        .then((data) => {
          if (data.status === "UserToAdmin") {
            toast.warn(
              this.state.createAdminData.email +
                " has been converted to an administrator",
            );
          } else if (data.status === "UserIsAdmin") {
            toast.warn(
              this.state.createAdminData.email + " is already an administrator",
            );
          } else if (data.status === "AdminToUser") {
            toast.warn(
              this.state.createAdminData.email + " is already an administrator",
            );
          } else if (data.status === "NoChange") {
            toast.warn(
              this.state.createAdminData.email + " is already an administrator",
            );
          } else {
            toast.success(
              "Invitation sent to " + this.state.createAdminData.email,
            );
          }
        })
        .catch((e) => {
          toast.error("Error creating admin: " + e.response.data.error);
        });

      let allUserList = await this.fetchUser();
      let adminList = allUserList.admins;
      this.setState({
        createAdminData: {},
        isCreateAdminPanelOpened: false,
        adminList,
      });
    }
  };

  createUser = async () => {
    this.setState({ isUserUpdated: false });

    // checking if the field is empty ?

    if (!this.state.createUserData.email) {
      this.setState({
        isUserEmailCorrect: false,
        userValidationMsg: "Mandatory field",
      });
      toast.error("Please check highlighted field(s).");
      return;
    }

    const validateUser = handleEmailValidation(this.state.createUserData.email);

    // checking if field is not empty then is email valid ?

    if (validateUser === false) {
      this.setState({
        isUserEmailCorrect: false,
        userValidationMsg: "Invalid email address",
      });
      toast.error("Please check highlighted field(s).");
      return;
    } else {
      this.setState({ isUserEmailCorrect: true, userValidationMsg: "" });
      await UserApi.createHotelUser({
        hotelIds: this.hotelIds,
        userEmail: this.state.createUserData.email,
        isAdmin: this.state.createUserData.isAdmin,
      })
        .then((data) => {
          if (data.status === "AdminToUser") {
            toast.warn(
              this.state.createUserData.email +
                " is an administrator and cannot be converted to a user",
            );
          } else if (data.status === "NoChange") {
            toast.warn(this.state.createUserData.email + " is already a user");
          } else {
            toast.success(
              "Invitation sent to " + this.state.createUserData.email,
            );
          }
        })
        .catch((e) => {
          console.log("error creating user", e);
          if (e.response.data.stack.includes("UsernameExistsException")) {
            toast.success("User permissions updated");
            return;
          }
          toast.error("Error creating user: " + e.response.data.error);
        });

      let allUserList = await this.fetchUser();
      let userList = allUserList.users;
      this.setState({
        createUserData: {},
        isCreateUserPanelOpened: false,
        userList,
      });
    }
  };

  resetUserPassword = async (email: string) => {
    await YieldSheetService.revanista.hotel.resetUserPassword(
      this.hotelIds,
      email,
    );
    let allUserList = await this.fetchUser();
    let userList = allUserList.users;
    toast.success("password reset");
    this.setState({
      createUserData: {},
      userList,
    });
  };

  deleteUser = async () => {
    await YieldSheetService.revanista.hotel.deleteUser(
      this.hotelIds,
      this.state.userTodelete,
    );
    if (this.state.subscriptionList.includes(this.state.userTodelete)) {
      const toDeleteFromSubscriptionList = this.state.subscriptionList.filter(
        (data: any) => data !== this.state.userTodelete,
      );
      console.log("found");

      await this.updateSubcriptionList(toDeleteFromSubscriptionList);
    }
    let allUserList = await this.fetchUser();
    let userList = allUserList.users;
    let adminList = allUserList.admins;
    this.setState({
      isUserDeleteDialogOpened: false,
      isAdminDeleteDialogOpened: false,
      userList,
      adminList,
    });
    toast.success("User deleted");
  };

  removeFromSubscription = (email: string) => {
    let updated = this.state.subscriptionList.filter((elem: string) => {
      return elem !== email;
    });
    this.updateSubcriptionList(updated);
  };

  addToSubscription = (email: any) => {
    this.setState({ isNotifyUpdated: false });
    let updated = this.state.selectedSubscriptionusers.concat(
      this.state.subscriptionList,
    );

    this.updateSubcriptionList(updated);
  };

  updateSubcriptionList = async (list: string[]) => {
    await YieldSheetService.revanista.hotel.editSubscriptionList(
      this.userListHotel.hotelId,
      list,
    );
    this.setState({
      subscriptionList: list,
      selectedSubscriptionusers: [],
      isSubscribePanelOpened: false,
    });
    await ConfigService.revanista.hotel.updateConfig(
      this.userListHotel.hotelId,
      this.props,
    );
    // this.props.onHotelUpdate(this.hotel);
    await ConfigService.revanista.hotel.updateConfig(
      this.hotel.hotelId,
      this.props,
    );
    //avoid hardrefreshing the whole scren
  };

  openDeleteDialog = (userEmail: string) => {
    this.setState({
      userTodelete: userEmail,
      isUserDeleteDialogOpened: true,
    });
  };

  openDeleteAdminDialog = (userEmail: string) => {
    this.setState({
      userTodelete: userEmail,
      isAdminDeleteDialogOpened: true,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      isUserDeleteDialogOpened: false,
      isAdminDeleteDialogOpened: false,
    });
  };

  cancelAppend = () => {
    this.setState({
      selectedSubscriptionusers: [],
      isSubscribePanelOpened: false,
    });
  };

  toggleSubscribePanel = () => {
    this.setState({
      isSubscribePanelOpened: !this.state.isSubscribePanelOpened,
    });
  };

  handleSubscriberSelection(event: any) {
    const value = event.target.value;
    let existingUserInList = this.state.subscriptionList;
    console.log(this.state.subscriptionList);

    let allUserList = this.state.adminList.concat(this.state.userList);

    const options = allUserList.filter(
      (user) => !existingUserInList.includes(user.email),
    );

    const emailFromOptions = options.map((data: any) => data.email);

    if (value[value.length - 1] === "all") {
      this.setState({
        selectedSubscriptionusers:
          this.state.selectedSubscriptionusers.length ===
          emailFromOptions.length
            ? []
            : emailFromOptions,
      });
      return;
    }
    this.setState({
      selectedSubscriptionusers: value as string[],
    });

    if (value) {
      this.setState({ isNotifyUpdated: true });
    }
  }

  fetchUser = async () => {
    let res = await YieldSheetService.revanista.hotel.listUser(
      this.hotelIds[0],
    );
    return res;
  };

  getAdminListCard(user: any, index: any) {
    let email: string = user.email;
    const { isOverflowAdmin } = this.state;
    return (
      <Card key={email}>
        <div
          ref={this.mainDivRefsAdmin[index]}
          className={
            isOverflowAdmin[index] ? "message_ticker_new" : "message_ticker"
          }
        >
          <h5
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginLeft: 15,
              fontSize: "1em",
            }}
          >
            {email}
          </h5>
        </div>
        {user.fullControl && (
          <SupervisedUserCircleIcon style={{ fill: "#3f51b5" }} />
        )}
        {user.status && user.status != "CONFIRMED" && (
          <HourglassFullIcon style={{ fill: "crimson" }} />
        )}

        <CardActions>
          {appStore.meta._user.permissions.admin[0] === "*" &&
            user.status &&
            user.status != "CONFIRMED" && (
              <Tooltip title="Reset Password">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    this.resetUserPassword(email);
                  }}
                >
                  <ResetIcon style={{ fill: "black" }} />
                </IconButton>
              </Tooltip>
            )}

          <Tooltip title="Remove">
            <IconButton
              id={`delete-admin-${email}-button`}
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                this.openDeleteAdminDialog(email);
              }}
            >
              <DeleteIcon style={{ fill: "crimson" }} />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  }

  getUserListCard(user: any, index: any) {
    console.log("user", user);

    let email: string = user.email;
    const { isOverflowUser } = this.state;
    return (
      <Card key={email}>
        <div
          ref={this.mainDivRefsUser[index]}
          className={
            isOverflowUser[index] ? "message_ticker_new" : "message_ticker"
          }
        >
          <h5
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginLeft: 15,
              fontSize: "1em",
            }}
          >
            {email}
          </h5>
        </div>
        {user.fullControl && (
          <SupervisedUserCircleIcon style={{ fill: "#3f51b5" }} />
        )}
        {user.status && user.status != "CONFIRMED" && (
          <HourglassFullIcon style={{ fill: "crimson" }} />
        )}
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
        ></Typography>

        <CardActions>
          {appStore.meta._user.permissions.admin[0] === "*" &&
            user.status &&
            user.status != "CONFIRMED" && (
              <Tooltip title="Reset Password">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    this.resetUserPassword(email);
                  }}
                >
                  <ResetIcon style={{ fill: "black" }} />
                </IconButton>
              </Tooltip>
            )}

          <Tooltip title="Remove">
            <IconButton
              id={`delete-user-${email}-button`}
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                this.openDeleteDialog(email);
              }}
            >
              <DeleteIcon style={{ fill: "crimson" }} />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  }

  getUserSubscriptionCard(user: any, index: any) {
    let email: string = typeof user == "string" ? user : user.email;
    const { isOverflowNoti } = this.state;
    return (
      <Card key={email}>
        <div
          ref={this.mainDivRefsNoti[index]}
          className={
            isOverflowNoti[index] ? "message_ticker_new" : "message_ticker"
          }
        >
          <h5
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginLeft: 15,
              fontSize: "1em",
              height: "15",
            }}
          >
            {email}
          </h5>
        </div>

        <CardActions>
          <Tooltip title="unsubscribe">
            <IconButton
              id={`unsubscribe-user-${email}-button`}
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                this.removeFromSubscription(email);
              }}
            >
              <DeleteIcon style={{ fill: "crimson" }} />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  }

  async componentDidMount() {
    this.users = YieldSheetService.revanista.hotel.listUser(this.hotel.hotelId);
    let allUserList = [];
    let userList = [];
    let adminList = [];
    try {
      allUserList = await this.fetchUser();
      userList = allUserList.users;
      adminList = allUserList.admins;
    } catch (err) {
      console.log("error fetching user list", err);
    }
    this.setState({ userList, adminList, isLoading: false });

    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
    return () => {
      window.removeEventListener("resize", this.checkOverflow);
    };
  }

  checkOverflow = () => {
    const updatedIsOverflowAdmin = this.mainDivRefsAdmin.map((ref) => {
      return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    });
    this.setState({ isOverflowAdmin: updatedIsOverflowAdmin });

    const updatedIsOverflowUser = this.mainDivRefsUser.map((ref) => {
      return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    });
    this.setState({ isOverflowUser: updatedIsOverflowUser });

    const updatedIsOverflowNoti = this.mainDivRefsNoti.map((ref) => {
      return ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    });
    this.setState({ isOverflowNoti: updatedIsOverflowNoti });
  };

  render() {
    let existingUserInList = this.state.subscriptionList;

    let allUserList = this.state.adminList.concat(this.state.userList);

    const filteredUsers = allUserList.filter(
      (user) => !existingUserInList.includes(user.email),
    );
    console.log("user", appStore.meta);

    const isAllSelected =
      (allUserList.length > 0 &&
        this.state.subscriptionList.length === allUserList.length) ||
      (filteredUsers.length > 0 &&
        filteredUsers.length === this.state.selectedSubscriptionusers.length);

    return (
      <MuiThemeProvider theme={theme}>
        <Backdrop
          className={theme}
          open={this.state.isLoading}
          invisible={true}
        >
          <CircularProgress color="inherit" id="circular-progress" />
        </Backdrop>
        <div>
          <br />
          <br />
          {appStore.meta._user.permissions.admin[0] !== "*" ? (
            <p
              style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
            >
              This page is admin locked. Please contact us should you need
              something changed.
            </p>
          ) : (
            <p></p>
          )}

          {appStore.meta._user.permissions.admin[0] === "*" && (
            <div>
              <SectionTitle {...{ value: "Administrators List" }} />
              <br />

              {!this.state.isCreateAdminPanelOpened && (
                <Button
                  id="create-admin-button"
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.toggleCreateAdminPanel}
                  style={{
                    width: "50px",
                    height: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <PersonAddIcon />
                </Button>
              )}
              <CSSTransition
                in={this.state.isCreateAdminPanelOpened}
                appear={true}
                exit={true}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <FormControl>
                  <TextField
                    id="outlined-basic"
                    label="email"
                    variant="outlined"
                    onChange={this.setNewAdminEmail}
                    style={{ marginLeft: 25 }}
                    inputProps={{ maxLength: 40 }}
                    error={this.state.isAdminEmailCorrect === false}
                    helperText={this.state.adminValidationMsg}
                  />
                  <div
                    style={{
                      maxHeight: "55px",
                      display: "inline-block",
                      marginLeft: 25,
                    }}
                  >
                    <Button
                      aria-label="cancel"
                      id="create-admin-red-check-mark"
                      onClick={this.toggleCreateAdminPanel}
                      variant="contained"
                      style={{
                        backgroundColor: "#808080",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="create-admin-green-check-mark"
                      onClick={this.createAdmin}
                      color="primary"
                      variant="contained"
                      style={{
                        backgroundColor: "#ce0e29",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </FormControl>
              </CSSTransition>

              <br />
              <Container>
                <List>
                  {this.state.adminList.map((user, index) =>
                    this.getAdminListCard(user, index),
                  )}
                </List>
              </Container>

              <br />
            </div>
          )}

          <Divider></Divider>

          <SectionTitle {...{ value: "Users List" }} />
          <br />

          {!this.state.isCreateUserPanelOpened && (
            <Button
              id="create-user-button"
              aria-label="split"
              variant="contained"
              color="primary"
              component="span"
              disabled={
                appStore.meta._user.permissions.admin[0] !== "*" ? true : false
              }
              onClick={this.toggleCreateUserPanel}
              style={{ width: "50px", height: "100%", marginBottom: "10px" }}
            >
              <PersonAddIcon />
            </Button>
          )}
          <CSSTransition
            in={this.state.isCreateUserPanelOpened}
            appear={true}
            exit={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <FormControl>
              <TextField
                id="outlined-basic"
                label="email"
                variant="outlined"
                onChange={this.setNewUserEmail}
                style={{ marginLeft: 25 }}
                inputProps={{ maxLength: 40 }}
                error={this.state.isUserEmailCorrect === false}
                helperText={this.state.userValidationMsg}
              />
              <div
                style={{
                  maxHeight: "55px",
                  display: "inline-block",
                  marginLeft: 25,
                }}
              >
                <Button
                  id="create-user-red-check-mark"
                  aria-label="cancel"
                  onClick={this.toggleCreateUserPanel}
                  variant="contained"
                  style={{
                    backgroundColor: "#808080",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id="create-user-green-check-mark"
                  aria-label="create"
                  onClick={this.createUser}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#ce0e29",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Save
                </Button>
              </div>
            </FormControl>
          </CSSTransition>

          <br />
          <Container>
            <List>
              {this.state.userList.map((user, index) =>
                this.getUserListCard(user, index),
              )}
            </List>
          </Container>
          <br />
          <Divider></Divider>
          <SectionTitle {...{ value: "Notification List" }} />
          <br />

          {!this.state.isSubscribePanelOpened && (
            <Button
              id="subscribe-user-button"
              aria-label="split"
              variant="contained"
              color="primary"
              component="span"
              disabled={
                appStore.meta._user.permissions.admin[0] !== "*" ? true : false
              }
              onClick={() => this.toggleSubscribePanel()}
              style={{ width: "50px", height: "100%", marginBottom: "10px" }}
            >
              <PersonAddIcon />
            </Button>
          )}
          <CSSTransition
            in={this.state.isSubscribePanelOpened}
            appear={true}
            exit={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <FormControl variant="outlined">
              <InputLabel id="mutiple-select-label" style={{ width: "250px" }}>
                Select users
              </InputLabel>
              <Select
                style={{ width: "250px" }}
                labelId="mutiple-select-label"
                label="Select users"
                name="selectUsers"
                multiple
                value={this.state.selectedSubscriptionusers}
                onChange={this.handleSubscriberSelection}
                renderValue={(selected: any) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {(selected as string[]).map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </div>
                )}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        this.state.selectedSubscriptionusers.length > 0 &&
                        this.state.selectedSubscriptionusers.length <
                          filteredUsers.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {filteredUsers.map((option: any) => (
                  <MenuItem key={option.email} value={option.email}>
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          this.state.selectedSubscriptionusers.indexOf(
                            option.email,
                          ) > -1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.email} />
                  </MenuItem>
                ))}
              </Select>
              <div style={{ display: "inline-block", marginLeft: 25 }}>
                <Button
                  id="subscribe-user-red-check-mark"
                  aria-label="cancel"
                  onClick={this.cancelAppend}
                  color="secondary"
                  variant="contained"
                  style={{
                    backgroundColor: "#808080",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id="subscribe-user-green-check-mark"
                  aria-label="create"
                  onClick={this.addToSubscription}
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: "#ce0e29",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Save
                </Button>
              </div>
            </FormControl>
          </CSSTransition>
          <br />
          <Container>
            <List>
              {this.state.subscriptionList.map((user: any, index: any) =>
                this.getUserSubscriptionCard(user, index),
              )}
            </List>
          </Container>
        </div>

        <Popup
          isPopupOpen={this.state.isUserDeleteDialogOpened}
          title="Remove User"
          content={[
            `Are you sure you want to remove "${this.state.userTodelete}" from this hotel?`,
            "Note that if this user has access to multiple properties, they will only be removed from this hotel.",
          ]}
          action={this.deleteUser}
          actionName="Remove User"
          cancelAction={this.closeDeleteDialog}
          cancelName="Cancel"
        />

        <Popup
          isPopupOpen={this.state.isAdminDeleteDialogOpened}
          title="Remove User"
          content={[
            `Are you sure you want to remove  admin " ${this.state.userTodelete} " from Ravanista?`,
          ]}
          action={this.deleteUser}
          actionName="Remove admin"
          cancelAction={this.closeDeleteDialog}
          cancelName="Cancel"
        />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(Users);
