/** Revanista Web Application entry point */

import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import { appStore } from "./App/store";
import { Provider } from "mobx-react";

if (process.env.REACT_APP_STAGE === "production") {
  console.log = () => {};
  console.info = () => {};
  console.debug = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const ROOT = (
  <Provider appStore={appStore}>
    <App store={appStore} />
  </Provider>
);
const root = createRoot(document.getElementById("root")!);
root.render(ROOT);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
