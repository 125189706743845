import { createTheme } from "@material-ui/core";

const rtTheme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        width: "calc(33% - 8px)",
        float: "left",
        marginLeft: "10px",
        marginTop: "10px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
      },
    },
    MuiTypography: {
      root: {
        float: "left",
        width: "100%!important",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
        width: 230,
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#fce8e6",
          color: "#d93025",
          fontWeight: "bolder",
        },
      },
    },
  },
});

const rpTheme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        height: 50,
        width: "calc(25% - 8px)",
        float: "left",
        marginLeft: "8px",
        marginTop: "8px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
        padding: 0,
        paddingLeft: 15,
        lineHeight: "50px",
      },
    },
    MuiTypography: {
      root: {
        lineHeight: "50px!important",
        width: "calc(100% - 90px)!important",
        float: "left",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#fce8e6",
          color: "#d93025",
          fontWeight: "bolder",
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 2,
        marginRight: 0,
      },
    },
  },
});

export interface State {
  hotel: any;
  deleteFormula: any;
  NewSetCreationMode: boolean;
  currentSetIndex: number;
  displayUpdateRateWarningOnsave: boolean;
  warningDialogOpened: boolean;
  seasonnalCreationMode: boolean;
  newSeasonnalLabel: string;
  newSeasonnalFormula: string;
  validateFullName: boolean;
  validateFullNameMsg: any;
  validateFormula: boolean;
  validateFormulaMsg: any;
  validateName: boolean;
  validateNameMsg: any;
  validateContractType: boolean;
  validateContractTypeMsg: any;
  validateTotalRoom: boolean;
  validateTotalRoomMsg: any;
  validateInventory: boolean;
  validateInventoryMsg: any;
  validateCmHotelCode: boolean;
  validateCmHotelCodeMsg: any;
  validatePmsHotelCode: boolean;
  validatePmsHotelCodeMsg: any;
  validateProviderKey: boolean;
  validateProviderKeyMsg: any;
  validatePmsUrlEndpoint: boolean;
  validatePmsUrlEndpointMsg: any;
  validateUserName: boolean;
  validateUserNameMsg: any;
  validateFelexTable: any;
  validateFelexTableMsg: any;
  validateOwsFelexTable: any;
  validateOwsFelexTableMsg: any;
  validateDefaultBar: any;
  validateDefaultBarMsg: any;
  validateMinRate: any;
  validateMinRateMsg: any;
  validateMaxRate: any;
  validateMaxRateMsg: any;
  validatePickupRange: any;
  validatePickupRangeMsg: any;
  validateOtaPickupRange: any;
  validateOtaPickupRangeMsg: any;
  infoIconStatus: boolean;
  setGuestline: boolean;
  realData: any;
  counter: number;
  onSave: boolean;
  isDataChange: boolean;
  hover: boolean;
  disableOTAPickup: boolean;
  isSyncOn: boolean;
  openRewardsPopup: boolean;
  rewardsPopupToBeOpen: boolean;
  originalHasRewardsValue: boolean;
}
export interface FormulaOverideState {
  formulas: any[];
  creationMode: boolean;
  newLabel: string;
  newFormula: string;
  isEdited: boolean;
  defaultLabel: string;
}
export interface IHotelConfigProps {
  hotel: any;
  currentUser: string;
  deleteFormula: any;
  onHotelUpdate: (hotel: any) => void;
}
export interface IFormulaOveridesConfigProps {
  hotel: any;
  deleteFormula: any;
  onUpdate: (hotel: any) => void;
  isEdited: any;
  router: any;
  isAdmin: any;
}

export { rpTheme, rtTheme };
