// @ts-nocheck

import { HotTable, HotTableProps } from "@handsontable-pro/react";
import {
  restrictions,
  auth,
  hotel as gnHotel,
  history as gnHistory,
} from "gn-shared";
import { DateTime } from "luxon";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "./yieldsheet.css";

import {
  IAvailabilityUpdate,
  IRateUpdate,
  IRestrictionsUpdate,
  ISuggestedBaseRateUpdate,
  IManualRateUpdate,
  IRewardsUpdate,
} from "../../models/api.models";
import { YieldApi } from "../../api/yield";
import { CellCoords, IYieldDay } from "../../models/yield.models";
import { rowType, Rowprop } from "./rowProp";
import {
  availabilityExceedsTotalRooms,
  cells,
  columns,
  colWidths,
} from "./yield-sheet.helpers";
import EventTable from "./subcomponents/eventTable/eventTable";
import { getRestrictionsMenuItems } from "./restrictions-menu";
import moment from "moment";

import MatrixModal from "./subcomponents/SuggestionModal/matrixesModal";

export interface IYieldSheetProps {
  permissions: any;
  hotel: any;
  store: any;
  onDataUpdate: () => void;
  rowProps: Rowprop;
  data: any;
  sheet: IYieldDay[];
  start: DateTime;
  end: DateTime;
  compset: any[];
  compareOtaRate: any;
  compareDemand: any;
}

interface State {
  hotel: any;
  forceRender: boolean;
}

type CellChange = [number, number, number | string, (number | string)?];

class YieldSheet extends Component<IYieldSheetProps, State> {
  state: State = {
    hotel: {},
    forceRender: false,
  };

  private suggestionModalInstanceRef = React.createRef<any>();
  private hotInstanceRef = React.createRef<HotTable>();
  private leftToSellUpdates: any = {};

  constructor(props: any) {
    super(props);
    this.state.hotel = gnHotel(this.props.hotel);
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.data === this.props.data) {
      return false;
    }
    return true;
  }

  openSuggestionModal = () => {
    this.suggestionModalInstanceRef.current.open();
  };

  handleEdit = (changes: CellChange[]) => {
    if (!changes) {
      return;
    }
    const { sheet } = this.props;
    const rateUpdates: IRateUpdate[] = [];
    const suggestedBaseRateUpdates: ISuggestedBaseRateUpdate[] = [];
    const availabilityUpdates: IAvailabilityUpdate[] = [];
    const manualRateUpdates: IManualRateUpdate[] = [];
    const rewardsUpdates: IRewardsUpdate[] = [];
    const rowsProps = this.props.rowProps;

    let columnCount = changes[0];
    console.log("changes===", columnCount);
    const day = sheet[(columnCount[1] as number) - 1];
    const todayDate = DateTime.fromMillis(day.dateMidday).toFormat(
      "yyyy-MM-dd",
    );

    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: todayDate,
      end: todayDate,
    };

    changes.forEach((change) => {
      const [row, col, prev, current] = change;
      let rp = rowsProps.get(row);
      let rowtype = rp.type;
      if (row === col && row === 0) {
        return;
      }

      if (current === prev) {
        return;
      }

      const day = sheet[(col as number) - 1];
      const isoDate = DateTime.fromMillis(day.dateMidday).toFormat(
        "yyyy-MM-dd",
      );

      const updateBase = {
        hotelId: this.props.hotel.hotelId,
        start: isoDate,
        end: isoDate,
      };

      const value: number = Number(current);

      let rowProp = rowsProps.get(row);
      if (rowProp && rowProp.type === "ratePlan") {
        manualRateUpdates.push({
          ...updateBase,
          rate: value * 100,
          invTypeCode: rowProp.roomTypeId,
          ratePlanID: rowProp.ratePlanId,
        });
      }

      switch (rowtype) {
        case rowType.suggestedBaseRate:
          console.log("value", value);

          suggestedBaseRateUpdates.push({
            ...updateBase,
            suggestedRate: value,
          });
          break;
        case rowType.actualBaseRate:
          let suggestedBaseRate = sheet[col - 1].suggestedBaseRate;
          // REV-911
          if (suggestedBaseRate === prev) {
            suggestedBaseRateUpdates.push({
              ...updateBase,
              suggestedRate: value,
            });
          }
          rateUpdates.push({
            ...updateBase,
            baseRate: value,
            col: col,
          });
          break;
        case rowType.actualOWSBaseRate:
          rateUpdates.push({
            ...updateBase,
            owsBaseRate: value,
            col: col,
          });
          break;
        case rowType.leftToSellUpdate:
          this.leftToSellUpdates[isoDate!] = value;
          break;
        case rowType.rewards:
          rewardsUpdates.push({
            ...updateBase,
            active: current === "yes" ? true : false,
          });
          break;
      }

      // REV-260
      if (rowtype === rowType.roomType) {
        availabilityUpdates.push({
          ...updateBase,
          availability: value,
          invTypeCode: rp.roomTypeId,
        });
        if (
          availabilityExceedsTotalRooms(this.props.hotel.meta.totalRooms, value)
        ) {
          toast.warn("Availability entered exceeds total number of rooms");
        }
      }
    });

    // REV-260
    if (availabilityUpdates.length) {
      YieldApi.updateAvailability(availabilityUpdates)
        .then((res: any) => {
          this.props.onDataUpdate();
        })
        .catch((err: any) => {
          toast.error("Error while updating availability");
          this.props.onDataUpdate();
        });
    }

    // if (rateUpdates.length && suggestedBaseRateUpdates.length) {
    //   YieldApi.updateRates(rateUpdates)
    //     .then((res: any) => {
    //       YieldApi.updateSuggestedBaseRate(suggestedBaseRateUpdates)
    //         .then((res: any) => {
    //           this.props.onDataUpdate();
    //         })
    //         .catch((err: any) => {
    //           toast("Error while matching suggested base rates to base rates", {
    //             type: "error",
    //           });
    //           this.props.onDataUpdate();
    //         });
    //     })
    //     .catch((err: any) => {
    //       toast("Error while updating rates", { type: "error" });
    //       this.props.onDataUpdate();
    //     });
    // }

    console.log("rateUpdates", rateUpdates);
    if (rateUpdates.length && !suggestedBaseRateUpdates.length) {
      rateUpdates.forEach((rateUpdate) => {
        if (columnCount[3] !== "") {
          YieldApi.bulkUpdateRates(rateUpdate)
            .then(async (res: any) => {
              if (
                columnCount[3] === day.suggestedBaseRate ||
                columnCount[3] === day.actualBaseRate
              ) {
                await YieldApi.removeSuggestedDataAfterAcceptOrReject(
                  updateBase,
                )
                  .then((res: any) => {})
                  .catch((err: any) => {
                    toast.error("Error while deleting suggested base rates");
                  });
                // return;
              }
              this.props.onDataUpdate();
            })
            .catch((err: any) => {
              toast.error("Error while updating rates");
              this.props.onDataUpdate();
            });
        } else if (columnCount[3] == "") {
          this.props.onDataUpdate();
        }
      });
    }

    if (suggestedBaseRateUpdates.length && !rateUpdates.length) {
      if (columnCount[3] !== "") {
        YieldApi.updateSuggestedBaseRate(suggestedBaseRateUpdates)
          .then(async (res: any) => {
            if (columnCount[3] === day.actualBaseRate) {
              await YieldApi.removeSuggestedDataAfterAcceptOrReject(updateBase)
                .then((res: any) => {})
                .catch((err: any) => {
                  toast.error("Error while deleting suggested base rates");
                });
            }
            this.props.onDataUpdate();
          })
          .catch((err: any) => {
            toast.error("Error while updating suggested base rates");
            this.props.onDataUpdate();
          });
      } else if (columnCount[3] === "") {
        this.props.onDataUpdate();
      }
    }
    if (manualRateUpdates.length) {
      YieldApi.updateManualRates(manualRateUpdates)
        .then((res: any) => {
          this.props.onDataUpdate();
        })
        .catch((err: any) => {
          toast.error("Error while updating manual rate");
          this.props.onDataUpdate();
        });
    }
    if (rewardsUpdates.length) {
      rewardsUpdates.forEach((rewardsUpdate) => {
        YieldApi.bulkUpdateRewards(rewardsUpdate)
          .then((res: any) => {
            this.props.onDataUpdate();
          })
          .catch((err: any) => {
            toast.error("Error while updating reward indicator");
            this.props.onDataUpdate();
          });
      });
    }
  };

  updateLeftToSell = (payload: any) => {
    YieldApi.updateLeftToSell(payload)
      .then((res: any) => {
        this.props.onDataUpdate();
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating pickup");
        this.props.onDataUpdate();
      });
  };

  updateManualRate = (payload: any) => {
    YieldApi.writeManualRate(payload)
      .then((res: any) => {
        this.props.onDataUpdate();
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating manual rate");
        this.props.onDataUpdate();
      });
  };

  updateRestrictions = (
    { row, col }: CellCoords,
    restrictionKey: RestrictionTypeKeys,
    action: "apply" | "remove",
    numberOfDays?: number,
  ) => {
    const rowProp = this.props.rowProps.get(row);

    const day = this.props.sheet[col - 1];
    const isoDate = DateTime.fromMillis(day.dateMidday).toFormat("yyyy-MM-dd");
    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: isoDate,
      end: isoDate,
    };
    let restriction: RestrictionToApply = {
      type: restrictions.TYPE[restrictionKey],
      roomType: rowProp!.roomTypeId,
      ratePlan: rowProp!.ratePlanId,
      dayCount: numberOfDays,
    };
    let dayRes = day.restrictions;
    let resType = restriction.type as string;

    if (action === "remove") {
      if (typeof rowProp!.ratePlanId == "undefined") {
        dayRes[rowProp!.roomTypeId].restriction[resType].stat = "edited";
        if (typeof dayRes[rowProp!.roomTypeId].ratePlans != "undefined") {
          for (let key in dayRes[rowProp!.roomTypeId].ratePlans) {
            if (
              typeof dayRes[rowProp!.roomTypeId].ratePlans[key].restriction !=
              "undefined"
            ) {
              if (
                typeof dayRes[rowProp!.roomTypeId].ratePlans[key].restriction[
                  resType
                ] != "undefined"
              ) {
                if (
                  dayRes[rowProp!.roomTypeId].ratePlans[key].restriction[
                    resType
                  ].stat === "published" &&
                  dayRes[rowProp!.roomTypeId].ratePlans[key].restriction[
                    resType
                  ].active
                ) {
                  dayRes[rowProp!.roomTypeId].ratePlans[key].restriction[
                    resType
                  ].stat = "edited";
                }
              }
            }
          }
        }
      }
    }

    let executeAction = (day: IYieldDay) => {
      const update: IRestrictionsUpdate = {
        ...updateBase,
        restrictions: dayRes,
      };

      YieldApi.writeRestrictions(update)
        .then((res: any) => {
          this.props.onDataUpdate();
        })
        .catch((err: any) => {
          console.log(err);
          toast.error("Error while updating restrictions");
          this.props.onDataUpdate();
        });
    };

    if (action === "apply") {
      restrictions.apply(day, restriction).then((day: IYieldDay) => {
        executeAction(day);
      });
    } else {
      restrictions
        .remove(rowProp.globalRes, day, restriction)
        .then((day: IYieldDay) => {
          executeAction(day);
        });
    }
  };

  private dayres = async (
    col: number,
    resType: string,
    action: "apply" | "remove",
    options: any,
  ) => {
    let rowProps = this.props.rowProps;
    let day = this.props.sheet[col - 1];
    const isoDate = DateTime.fromMillis(day.dateMidday).toFormat("yyyy-MM-dd");
    let updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: isoDate,
      end: isoDate,
    };
    if (options.event) {
      let e: string = options.event;
      //@ts-ignore
      updateBase[e] = true;
    }

    for (let i = 0; i <= rowProps.lastRowIndex; i++) {
      let rp = rowProps.get(i);
      const restriction: RestrictionToApply = {
        type: resType,
        roomType: rp.roomTypeId,
        ratePlan: rp.ratePlanId,
        dayCount: options && options.dayCount ? options.dayCount : null,
      };
      switch (options.subCat) {
        case "all":
          if (action === "apply") {
            day = await restrictions.apply(day, restriction);
          } else {
            day = await restrictions.remove(null, day, restriction);
          }
          break;
        case "ota":
          if (rp.ratePlanId && rp.isOTAOnly) {
            if (action === "apply") {
              day = await restrictions.apply(day, restriction);
            } else {
              day = await restrictions.remove(null, day, restriction);
            }
          }
          break;
        case "ows":
          if (rp.ratePlanId && rp.isOWSOnly) {
            if (action === "apply") {
              day = await restrictions.apply(day, restriction);
            } else {
              day = await restrictions.remove(null, day, restriction);
            }
          }
          break;
        case "gds":
          if (rp.ratePlanId && rp.isGDSOnly) {
            if (action === "apply") {
              day = await restrictions.apply(day, restriction);
            } else {
              day = await restrictions.remove(null, day, restriction);
            }
          }
          break;
        default:
          if (action === "apply") {
            day = await restrictions.apply(day, restriction);
          } else {
            day = await restrictions.remove(null, day, restriction);
          }
      }
    }
    const update: IRestrictionsUpdate = {
      ...updateBase,
      restrictions: day.restrictions,
    };

    return YieldApi.writeRestrictions(update);
  };

  bulkUpdateOwsOverride = async (selection: any, label: string) => {
    let start, end;
    if (selection.length === 1) {
      start = selection[0].start;
      end = selection[0].end;
    }
    if (selection.length > 1) {
      start = selection[0].start;
      end = selection[selection.length - 1].end;
    }

    let startCol = start.col;
    let endCol = end.col;
    let dayStart = this.props.sheet[startCol - 1];
    let dayEnd = this.props.sheet[endCol - 1];
    const startIsoDate = DateTime.fromMillis(dayStart.dateMidday).toFormat(
      "yyyy-MM-dd",
    );
    const endIsoDate = DateTime.fromMillis(dayEnd.dateMidday).toFormat(
      "yyyy-MM-dd",
    );
    let update = {
      hotelId: this.props.hotel.hotelId,
      start: startIsoDate,
      end: endIsoDate,
      label: label,
    };
    YieldApi.bulkUpdateOwsOverride(update)
      .then((res: any) => {
        this.props.onDataUpdate();
      })
      .catch((err: any) => {
        toast.error("Error while setting OWS override");
      });
  };

  closeOrOpenDay = async (
    hotel: any,
    col: number,
    action: "apply" | "remove",
    subCat?: string,
  ) => {
    let event = gnHistory.events.closeDay;
    if (action === "remove") {
      event = gnHistory.events.openDay;
    }
    this.dayres(col, restrictions.TYPE.STOP_SELL, action, { event, subCat })
      .then(() => {
        let day = this.props.sheet[col - 1];
        if (!hotel.meta.hasRewards || action === "remove" || !day.rewards) {
          return this.props.onDataUpdate();
        }
        const isoDate = DateTime.fromMillis(day.dateMidday).toFormat(
          "yyyy-MM-dd",
        );

        let update = {
          hotelId: this.props.hotel.hotelId,
          start: isoDate,
          end: isoDate,
          active: false,
        };
        YieldApi.bulkUpdateRewards(update).then((res: any) => {
          this.props.onDataUpdate();
        });
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error while updating restrictions");
      });
  };

  minDayForDay = (col: number, action: "apply" | "remove") => {
    let event = gnHistory.events.rmLos2ForDay;
    if (action === "apply") {
      event = gnHistory.events.addLos2ForDay;
    }
    this.dayres(col, restrictions.TYPE.MIN_DAYS, action, {
      event,
      dayCount: 2,
    }).then(() => {
      return this.props.onDataUpdate();
    });
  };

  applyLeftToSellLiveInventory = (e: any) => {
    e.stopImmediatePropagation();
    if (Object.keys(this.leftToSellUpdates).length === 0) {
      return;
    }

    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: this.props.start.toFormat("yyyy-MM-dd"),
      end: this.props.end.toFormat("yyyy-MM-dd"),
      updates: this.leftToSellUpdates,
    };
    this.leftToSellUpdates = {};
    YieldApi.updateLeftToSellList(updateBase)
      .then((res: any) => {
        this.props.onDataUpdate();
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating pickup");
        this.props.onDataUpdate();
      });
  };

  computeLeftToSell = (e: any) => {
    e.stopImmediatePropagation();
    if (Object.keys(this.leftToSellUpdates).length === 0) {
      return;
    }

    // Check if any value in leftToSellUpdates is a decimal number
    for (const key in this.leftToSellUpdates) {
      if (this.leftToSellUpdates.hasOwnProperty(key)) {
        const value = Number(this.leftToSellUpdates[key]);
        if (!Number.isInteger(value)) {
          toast("Left to sell cannot be a decimal", { type: "error" });
          return;
        }
      }
    }
    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: this.props.start.toFormat("yyyy-MM-dd"),
      end: this.props.end.toFormat("yyyy-MM-dd"),
      updates: this.leftToSellUpdates,
    };
    this.leftToSellUpdates = {};
    YieldApi.updateLeftToSellList(updateBase)
      .then((res: any) => {
        this.props.onDataUpdate();
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating pickup");
        this.props.onDataUpdate();
      });
  };

  getActiveHotel = () => {
    return this.state.hotel;
  };

  render() {
    var { permissions, sheet, store } = this.props;
    (window as any).sheet = sheet;

    permissions.isReadOnly = auth.isReadOnly(
      permissions,
      this.state.hotel.hotelId,
    );
    permissions.canEdit = auth.checkAuthClient(
      permissions,
      this.state.hotel.hotelId,
      auth.editPermission,
    );
    var dates = sheet.map((day) => DateTime.fromMillis(day.dateMidday));
    console.log("dates", dates);
    var displayRange =
      sheet.length > 0
        ? {
            start: moment(sheet[0].hrDate).valueOf(),
            end: moment(sheet[sheet.length - 1].hrDate).valueOf(),
          }
        : {};
    var self = this;
    console.log("yieldsheet data", sheet, this.props.rowProps.rtConf);

    const hotTableProps: HotTableProps = {
      renderAllRows: true,
      data: this.props.data,
      cells:
        sheet &&
        cells(this.state.hotel, permissions, this.props.rowProps, sheet),
      columns: columns(
        displayRange,
        this.state.hotel,
        store.yieldSheet,
        permissions,
        sheet,
        this.props.compset,
        this.props.compareOtaRate,
        this.props.compareDemand,
        this.props.rowProps,
        this.openSuggestionModal,
        () => {
          this.props.onDataUpdate();
        },
        this.computeLeftToSell,
      ),
      afterChange(changes: CellChange[]) {
        self.handleEdit(changes);
      },

      contextMenu: {
        items: getRestrictionsMenuItems(
          self,
          this.props.rowProps,
          permissions,
          auth.isReadOnly(permissions, this.state.hotel.hotelId),
          this.props.store,
        ),
      },
      beforeOnCellMouseDown: function (e: any) {
        if (
          e.target.textContent.includes("Left to Sell") ||
          e.target.textContent.includes("Apply") ||
          e.target.textContent.includes("Clear")
        ) {
          e.stopImmediatePropagation();
        }
      },

      rowHeaders: false,
      stretchH: "all",
      colWidths: colWidths(sheet.length),
      preventOverflow: true,
      fixedColumnsLeft: 1,
      zIndex: 0,
      rowHeights: 35,
      hiddenRows: {
        rows: this.props.rowProps.hiddenRows,
        indicators: true,
      },

      manualColumnResize: false,
      trimColumns: true,
      autoRowSize: false,
      viewportColumnRenderingOffset: 100,
    } as HotTableProps; // this is needed because nestedRows is experimental and not yet in the official typings
    return (
      <section
        className="fullSection"
        style={{
          overflow: "visible",
          zIndex: 0,
          position: "relative",
          // border: "2px solid black"
        }}
      >
        <div
          style={{
            width: "fit-content",
            zIndex: 1,
            position: "sticky",
            top: 130,
            // marginBottom: "-10000px",
          }}
        >
          <EventTable
            key={sheet[0].dateMidday + sheet[sheet.length - 1].dateMidday}
            {...{
              appStore: this.props.store,
              dates: dates,
              hotel: this.state.hotel,
              ys: this,
            }}
          />
        </div>

        <div
          // Awful hack to hide to overflow
          style={{
            backgroundColor: "white",
            height: 0,
            position: "fixed",
            top: 130,
            width: "100%",
            zIndex: 1,
          }}
        >
          &nbsp;
        </div>
        <div
          style={{
            width: "fit-content",
            zIndex: 0,
            position: "relative",
            bottom: 7,
          }}
        >
          <HotTable ref={this.hotInstanceRef} {...hotTableProps} />
        </div>
        <MatrixModal
          ref={this.suggestionModalInstanceRef}
          hotel={this.state.hotel}
        />
      </section>
    );
  }
}

export { YieldSheet };
