import { FormikProps, FormikState } from "formik";

export function showError<T>(
  formState: FormikState<T>,
  fieldName: keyof T,
): boolean {
  return !!(formState.errors[fieldName] && formState.touched[fieldName]);
}

export function getFieldClasses<T>(
  formState: FormikState<T>,
  fieldName: keyof T,
  size?: "sm" | "lg",
): string {
  const baseClass = "form-control";
  const sizeClass = size ? `form-control-${size}` : "";
  const validationClass = showError(formState, fieldName) ? "is-invalid" : "";

  return `${baseClass} ${sizeClass} ${validationClass}`;
}

export function resetFormError(formState: FormikProps<any>) {
  formState.setStatus({ error: null });
}
