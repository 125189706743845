import { AdminApi } from "../../../api/admin";

const revanista = {
  initialize: (currentUser: any, props: any) => {
    props.appStore.requests.setAdmin("getUsersHotelsMeta", "fetching");
    return AdminApi.getUsersHotelsMeta()
      .then((response: any) => {
        props.appStore.requests.setAdmin("getUsersHotelsMeta", "success");
        const payload = JSON.parse(response.Payload);
        props.appStore.meta.setUser("hotels", payload);

        // set the current hotel after refresh

        const pathname = window.location.pathname;
        const parts = pathname.split("/");
        const firstRoute = parseInt(parts[1]);

        const allHotels = props.appStore.meta.user.hotels;

        let paramsHotel =
          allHotels.filter((data: any) => data.hotelId == firstRoute) ||
          props.appStore.meta.user.hotels;

        let idData = paramsHotel.length == 0 ? allHotels[0] : paramsHotel[0];

        console.log(idData);

        props.appStore.changeActiveHotel(idData);

        return payload;
      })
      .catch((err: any) => {
        props.appStore.requests.setAdmin("getUsersHotelsMeta", "error");
        console.log("error", err);

        throw err;
      });
  },
};

const AppService = {
  revanista: revanista,
};

export { AppService };
