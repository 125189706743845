import { Component, Fragment } from "react";
import { AppStore } from "../../../store";

import { CellValue } from "react-table";
import { DateTime } from "luxon";
import moment from "moment";
import { HotTable, HotTableProps } from "@handsontable-pro/react";
import { dom, GridSettings, renderers } from "handsontable";
import React from "react";
import { HistoryModal } from "../historymodal";
import { getItems } from "./contextMenu";
import { BackendEvent, CellCoords } from "../../../../models/yield.models";
import changeHandlers from "./changeHandlers";
import { YieldSheetService } from "../../../services";
import { colWidths } from "../../yield-sheet.helpers";
import "./eventTable.css";

interface State {
  hModalIsOpen: boolean;
  data: any[];
  backendEvent: BackendEvent[];
  dates: DateTime[];
  mergedCells: any[];
}

type CellChange = [number, number, number | string, (number | string)?];

class EventTable extends Component<{ appStore: AppStore }, State> {
  state: State = {
    hModalIsOpen: false,
    data: [],
    backendEvent: [],
    dates: [],
    mergedCells: [],
  };

  private historyModalInstanceRef = React.createRef<any>();
  hotel: any;
  ys: any;

  openHistoryModal = (index: number) => {
    this.setState({ hModalIsOpen: true });
    this.historyModalInstanceRef.current
      ? this.historyModalInstanceRef.current.open(
          this.state.dates[index].toFormat("yyyy-MM-dd"),
        )
      : null;
  };

  handleContextMenuAction(key: string, selection: any) {
    let e = this.eventFromCoords(selection[0].start, this.state.backendEvent);
    changeHandlers.handleEventActions(
      this.hotel.hotelId,
      key,
      e!,
      this.fetchEvent,
    );
  }

  afterMerge(range: any, parent: any, plugginCalled: boolean) {
    if (!plugginCalled) {
      changeHandlers.extendEvent(
        this.eventFromCoords(range.from, this.state.backendEvent)!,
        range.to,
        range.from,
        this.fetchEvent,
      );
    }
  }

  afterUnmergeCells = (range: any, plugginCalled: boolean) => {
    changeHandlers.reduceEvent(
      this.eventFromCoords(range.from, this.state.backendEvent)!,
      this.fetchEvent,
    );
  };

  eventFromCoords = (
    coords: CellCoords,
    eventList: BackendEvent[],
  ): BackendEvent | null => {
    for (let event of eventList) {
      if (
        coords.col - 1 >= event.startIdx! &&
        coords.col - 1 <= event.endIdx!
      ) {
        return event;
      }
    }
    return null;
  };

  handleEdit = (changes: CellChange[]) => {
    if (!this.state.dates || !changes || changes[0][0] == 0) {
      return;
    }
    console.log("changes--", changes[0][2], changes);

    let col = changes[0][1];
    let row = changes[0][1];
    if (!this.state.dates[col - 1]) {
      return;
    }
    let newValue = changes[0][3] as string;

    let event = this.eventFromCoords({ col, row }, this.state.backendEvent);
    if (!event) {
      //time to inster a new one
      let s = this.state.dates[col - 1].toFormat("yyyy-MM-dd");
      event = {
        hotelId: this.hotel.hotelId,
        start: s,
        end: s,
        value: newValue,
      };
    } else {
      event.value = newValue;
    }
    changeHandlers.updateEvent(event, this.fetchEvent);
  };

  getDisplayValue = (eventData: any) => {
    let res: CellValue[][];
    res = [...new Array(3)].map(() => []);
    for (let columnsIdx in eventData) {
      for (let rowIdx in eventData[columnsIdx]) {
        res[Number(columnsIdx)][Number(rowIdx)] =
          eventData[Number(columnsIdx)][Number(rowIdx)].value;
      }
    }
    return res;
  };

  fetchEvent = () => {
    console.log("yield sheet event");

    return new Promise<void>(async (resolve, reject) => {
      if (!this.state.dates[0]) {
        return resolve();
      }
      let request = {
        hotelId: this.hotel.hotelId,
        start: moment(this.state.dates[0].toFormat("yyyy-MM-dd"))
          .add(-14, "days")
          .format("YYYY-MM-DD"),
        end: moment(
          this.state.dates[this.state.dates.length - 1].toFormat("yyyy-MM-dd"),
        ).format("YYYY-MM-DD"),
      };
      try {
        let res =
          await YieldSheetService.revanista.yieldsheet.days.fetchCalendarEvents(
            request,
          );

        this.setState({ backendEvent: res });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  };

  computeMergedCells = (events: BackendEvent[]) => {
    if (!this.state.dates || !this.state.dates[0]) {
      return [];
    }
    let mergeCells: any[] = [];
    let firstCellDate = moment(this.state.dates[0].toFormat("yyyy-MM-dd"));
    let i = 0;
    for (let event of events) {
      event.startIdx = Math.max(
        moment(event.start).diff(firstCellDate, "days"),
        0,
      );
      event.endIdx = Math.min(
        moment(event.end).diff(firstCellDate, "days"),
        this.state.dates.length - 1,
      );

      let d = event.endIdx! - event.startIdx!;
      if (d >= 0) {
        mergeCells.push({
          row: 2,
          col: event.startIdx! + 1,
          colspan: d + 1,
          rowspan: 1,
        });
      }

      if (event.endIdx! < 0) {
        //this event is outside the display range
        delete event.startIdx;
        delete event.endIdx;
      }
      events[i] = event;
      i++;
    }
    return mergeCells;
  };

  componentDidMount() {
    this.fetchEvent();
  }

  constructor(props: any) {
    super(props);
    this.state.dates = props.dates;
    this.hotel = props.hotel;
    this.ys = props.ys;
    this.afterMerge = this.afterMerge.bind(this);
  }

  private eventTableInstanceRef = React.createRef<HotTable>();

  prepareEventData(backendEvents: BackendEvent[]) {
    let data: CellValue[][];
    data = [...new Array(3)].map(() => []);
    if (this.state.dates.length == 0) {
      return data;
    }
    this.state.dates.forEach((date, idx) => {
      data[0][idx + 1] = {
        className: "strong black htDimmed",
        value: date.toFormat("cccc"),
      };
      data[1][idx + 1] = {
        className: " no-wrap inverse padded htDimmed",
        value: date.toFormat("d-LLL"),
      };
      backendEvents.forEach((event) => {
        if (idx == event.startIdx) {
          console.log("value0000000", event.value);

          data[2][idx + 1] = {
            value: event.value == "undefined" ? "" : event.value,
            className: event.type,
          };
        }
      });
    });

    return data;
  }

  render() {
    "RENDER EVENT CELLS";
    let merged = this.computeMergedCells(this.state.backendEvent);
    let eventData = this.prepareEventData(this.state.backendEvent);
    let displayData = this.getDisplayValue(eventData);
    var weekendCols = new Set();

    let eventTableProps = {
      data: displayData,
      afterChange: this.handleEdit,

      afterMergeCells: this.afterMerge,
      afterUnmergeCells: this.afterUnmergeCells,
      columns: (index: number | undefined) => {
        let renderer: renderers.Base = (
          instance,
          TD,
          row,
          col,
          prop,
          value,
          cellProperties,
        ) => {
          //i need to get the instance, so context menu manipualtion are done here !
          dom.empty(TD);
          const span = document.createElement("span");

          if (col > 0) {
            let day = displayData[1][col];
            let dow = moment(day).day();
            if (dow === 4 || dow === 5) {
              TD.className =
                eventData[row][col] && eventData[row][col].className
                  ? "eventTableCell weekend " + eventData[row][col].className
                  : "eventTableCell weekend";
            } else {
              TD.className =
                eventData[row][col] && eventData[row][col].className
                  ? "eventTableCell " + eventData[row][col].className
                  : "eventTableCell";
            }
          }
          if (col === 0) {
            if (row === 1) {
              span.innerText = "Date";
              TD.className += " no-wrap strong inverse padded htDimmed";
              TD.appendChild(span);
            }
            if (row === 2) {
              span.innerText = "Notes";
              TD.className += " no-wrap strong black padded htDimmed";
              TD.appendChild(span);
            }
            return TD;
          }
          if (row === 1) {
            const button = document.createElement("button");
            button.className = "fas fa-newspaper btn-history";
            button.innerText = "";

            button.addEventListener("click", (event) => {
              this.openHistoryModal(col - 1);
              event.stopPropagation();
              event.preventDefault();
            });
            const span = document.createElement("span");
            span.innerText = value;
            TD.appendChild(span);
            TD.appendChild(button);
            TD.className += " no-wrap inverse padded htDimmed";
            return TD;
          }

          span.innerText = value;
          TD.appendChild(span);
          return TD;
        };

        return { renderer };
      },
      cells: (row?: number, col?: number, prop?: object): GridSettings => {
        return { readOnly: false };
      },
      colWidths: colWidths(this.state.dates.length),
      contextMenu: {
        items: getItems(this.ys, this, false),
      },

      mergeCells: merged,
      autoWrapRow: false,
      stretchH: "all",
      rowHeaders: false,
      fixedColumnsLeft: 1,
      fixedRowsTop: 1,
      viewportColumnRenderingOffset: 100,

      rowHeights: function (row: number) {
        return row == 2 ? 35 * 3 : 35;
      },
      trimColumns: true,
      renderAllRows: true,
      // autoRowSize: false,
      manualRowResize: true,
      preventOverflow: true,
    } as HotTableProps;

    if (this.state.dates.length == 0) {
      return <div></div>;
    }
    return (
      <Fragment>
        {this.state.hModalIsOpen && (
          <HistoryModal
            ref={this.historyModalInstanceRef}
            {...{
              hotel: this.hotel,
            }}
          />
        )}
        <HotTable
          ref={this.eventTableInstanceRef}
          {...(eventTableProps as HotTableProps)}
        />
      </Fragment>
    );
  }
}
export default EventTable;
