import { RevanistaError } from "./revanista-error";

// REV-306 PR review recommended making the basic exception handling in the
// stores more DRY by wrapping the logic in a function
const logError = (err: RevanistaError | Error, message?: string) => {
  if (message) {
    console.error(message);
  } else {
    console.error(err);
  }
};

const tryCatchLog = (fn: Function) => {
  try {
    return fn();
  } catch (err) {
    try {
      if (err instanceof Error) {
        throw new RevanistaError(err.message);
      }
    } catch (err) {
      if (err instanceof Error) {
        logError(err);
      }
    }
  }
};

export { tryCatchLog };
