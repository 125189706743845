import { action, computed, decorate, observable } from "mobx";
import { tryCatchLog } from "../common/exception-handling";

class YieldSheetStore {
  _rootStore: any;
  _availabilities: any;
  _days: any;
  _rows: any;

  constructor(rootStore: any) {
    this._rootStore = rootStore;
    this._availabilities = {};
    this._days = { raw: [] };
    this._rows = { availability: [] };
  }

  get availabilities() {
    const self = this;
    return tryCatchLog(() => self._availabilities);
  }

  setAvailabilities(payload: any) {
    const self = this;
    return tryCatchLog(
      () =>
        (self._availabilities[`${payload.colIdx}:${payload.rowIdx}`] = payload),
    );
  }

  get days() {
    const self = this;
    return tryCatchLog(() => self._days);
  }

  setDays(target: string, payload: any) {
    return tryCatchLog(() => {
      return (this._days[target] = payload.map((day: any, idx: number) => {
        return { dayIdx: idx + 1, day };
      }));
    });
  }

  get rows() {
    const self = this;
    return tryCatchLog(() => self._rows);
  }

  setRows(target: string, payload: any) {
    return tryCatchLog(() => {
      const self = this;
      switch (target) {
        case "availability":
          if (
            self._rows.availability &&
            self._rows.availability.indexOf(payload) === -1
          ) {
            return self._rows.availability.push(payload);
          }
          break;
        default:
        // do nothing
      }
    });
  }

  get serialized(): any {
    return {
      availabilities: this._availabilities,
      days: this._days,
      rows: this._rows,
    };
  }

  initialize(options: any): any {
    const self = this;
    return tryCatchLog(() => {
      self._availabilities = options.availabilities
        ? options.availabilities
        : self._availabilities;
      self._days = options.days ? options.days : self._days;
      self._rootStore = options.rootStore ? options.rootStore : self._rootStore;
      self._rows = options.rows ? options.rows : self._rows;
      return { ...self.serialized };
    });
  }
}

decorate(YieldSheetStore, {
  _availabilities: observable,
  availabilities: computed,
  setAvailabilities: action,
  _days: observable,
  days: computed,
  setDays: action,
  _rows: observable,
  rows: computed,
  setRows: action,
  serialized: computed,
});

export { YieldSheetStore };
