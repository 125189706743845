import { AuthUtils } from "../../auth/utils";
import { Auth } from "aws-amplify";

const revanista = {
  user: {
    identify: (props: any) => {
      props.appStore.requests.setAuth("signin", "fetching");
      props.appStore.requests.setAuth("signin", "success");
      try {
        console.log("auth user", AuthUtils);
        const currentUser = {
          username: AuthUtils.username(props.authData),
          permissions: AuthUtils.permissions(props.authData),
          monthlyRateReminder: AuthUtils.monthlyRateReminder(props.authData),
          zwtToken: AuthUtils.zwtToken(props.authData),
        };
        props.appStore.meta.setUser("username", currentUser.username);
        props.appStore.meta.setUser("permissions", currentUser.permissions);
        props.appStore.meta.setUser(
          "monthlyRateReminder",
          currentUser.monthlyRateReminder,
        );
        props.appStore.meta.setUser("zwtToken", currentUser.zwtToken);
        return currentUser;
      } catch (err) {
        props.appStore.requests.setAuth("signin", "error");
        return;
      }
    },
    signOut: (props: any) => {
      return Auth.signOut().then(() => {
        props.appStore.meta.setUser("username", null);
        props.appStore.meta.setUser("permissions", null);
        props.onStateChange("signedOut", null);
      });
    },
  },
};

const AuthService = {
  revanista: revanista,
};

export { AuthService };
