type ConstructorParams = any[];

class RevanistaError extends Error {
  constructor(message: string, ...params: ConstructorParams) {
    // Pass remaining arguments (including vendor specific ones) to parent
    super(...params);
    // Maintain V8 stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RevanistaError);
    }
    this.name = "RevanistaError";
    this.message = message;
  }
}

export { RevanistaError };
