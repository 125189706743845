import { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

interface popupProps {
  isPopupOpen: boolean;
  title: string;
  content: any[];
  action: () => void;
  actionName: string;
  cancelAction: () => void;
  cancelName: string;
}

class Popup extends Component<popupProps> {
  render() {
    return (
      <Dialog
        open={this.props.isPopupOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: "black", height: "61px" }}
        >
          <span
            style={{
              color: "white",
            }}
          >
            {this.props.title}
          </span>
        </DialogTitle>
        <DialogContent style={{ marginTop: "10px" }}>
          {this.props.content.map((item, index) => (
            <DialogContentText id="alert-dialog-description" key={index}>
              {item}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          {this.props.cancelName !== "NA" && (
            <Button
              onClick={() => this.props.cancelAction()}
              color="secondary"
              variant="contained"
              style={{
                backgroundColor: "#808080",
                color: "#ffffff",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              {this.props.cancelName}
            </Button>
          )}
          <Button
            onClick={() => {
              this.props.action();
            }}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#ce0e29",
              color: "#ffffff",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            {this.props.actionName}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Popup;
