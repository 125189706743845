import { CellCoords } from "../../../../models/yield.models";
import { HotTable } from "@handsontable-pro/react";

interface ContextMenuItem {
  name: string;
  key?: string;
  callback?: (
    name: string,
    selection: Array<{ start: CellCoords; end: CellCoords }>,
    clickEvent: any,
  ) => void;
  disabled: () => boolean;
  hidden: () => boolean;
  isCommand?: boolean;
  submenu?: any;
}

export function getItems(
  this: any,
  ys: any,
  instance: any,
  isDisabled: boolean | null,
): {
  [item: string]: ContextMenuItem;
} {
  if (isDisabled) {
    return {};
  }
  // horrible hack for production

  let items = {
    mergeCells: {
      isDisabled,
      hidden: function () {
        const hotInstance: HotTable["hotInstance"] = this;
        const { row } = hotInstance.getSelectedRangeLast().highlight;
        return row != 2;
      },
    } as unknown as ContextMenuItem,
    CLOSE_DAY: getDaySubMenuItem(
      ys,
      "apply",
      "Close Day",
      isDisabled,
      "stop_sell" as RestrictionTypeKeys,
    ),
    OPEN_DAY: getDaySubMenuItem(
      ys,
      "remove",
      "Open Day",
      isDisabled,
      "stop_sell" as RestrictionTypeKeys,
    ),
    MINLOS2: getDayMenuItem(
      ys,
      "apply",
      "Apply min LOS 2",
      isDisabled,
      "min_days" as RestrictionTypeKeys,
    ),
    REMOVEMINLOS: getDayMenuItem(
      ys,
      "remove",
      "Remove min LOS",
      isDisabled,
      "min_days" as RestrictionTypeKeys,
    ),

    NOTYFYGNH: getEventMenuItem(
      instance,
      "notifygnh",
      "Notify GN",
      isDisabled,
      null,
    ),
  };

  return items;
}

function getEventMenuItem(
  instance: any,
  key: string,
  name: string,
  isDisabled: boolean | null,
  submenu: any | undefined,
): ContextMenuItem {
  if (submenu) {
    for (let optionIdx in submenu.items) {
      let option = submenu.items[optionIdx];
      option.callback = (itemName: any, selection: any) => {
        instance.handleContextMenuAction(itemName, selection);
      };
    }
  }
  let res = {
    name,
    key,
    submenu,
    disabled() {
      //not so necessary since we hide all disabled items!
      return false;
    },
    hidden() {
      if (isDisabled) {
        return true;
      }
      const hotInstance: HotTable["hotInstance"] = this;
      if (hotInstance.getSelectedLast()[1] === 0) {
        return true;
      }
      const { row } = hotInstance.getSelectedRangeLast().highlight;
      return row != 2;
    },
    callback: (itemName: any, selection: any) => {
      instance.handleContextMenuAction(itemName, selection);
    },
  };
  if (!submenu) {
    delete res.submenu;
  }
  console.log("=======>>>>>>", res);

  return res;
}

function getDaySubMenuItem(
  ys: any,
  type: string,
  name: string,
  isDisabled: boolean | null,
  restrictionType: RestrictionTypeKeys,
): ContextMenuItem {
  let contextMenu: ContextMenuItem = {
    name,
    key: `${type}_day`,
    disabled() {
      return false;
    },
    hidden() {
      if (isDisabled) {
        return true;
      }
      const hotInstance: HotTable["hotInstance"] = this;
      const selectedLast = hotInstance.getSelectedLast();
      const selectedRangeLast = hotInstance.getSelectedRangeLast();

      if (!selectedLast || !selectedRangeLast) {
        return true;
      }

      let col = selectedLast[1];
      if (col === 0) {
        return true;
      }
      const { row } = selectedRangeLast.highlight;
      return row != 1;
    },
    callback: (itemName: any, selection: any) => {},
  };
  let items: any[] = [];
  items.push({
    key: `${type}_day` + ":all",
    name: "All",
    callback: (itemName: any, selection: any) => {
      console.log("sel=>", selection, restrictionType);
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(ys.getActiveHotel(), col, type, "all");
          break;
      }
    },
  });
  items.push({
    key: `${type}_day` + ":ota",
    name: "OTA",
    callback: (itemName: any, selection: any) => {
      console.log("sel=>", selection, restrictionType);
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(ys.getActiveHotel(), col, type, "ota");
          break;
      }
    },
  });
  items.push({
    key: `${type}_day` + ":ows",
    name: "OWS",
    callback: (itemName: any, selection: any) => {
      console.log("sel=>", selection, restrictionType);
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(ys.getActiveHotel(), col, type, "ows");
          break;
      }
    },
  });
  items.push({
    key: `${type}_day` + ":gds",
    name: "GDS",
    callback: (itemName: any, selection: any) => {
      console.log("sel=>", selection, restrictionType);
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(ys.getActiveHotel(), col, type, "gds");
          break;
      }
    },
  });
  contextMenu.submenu = { items };

  return contextMenu;
}

function getDayMenuItem(
  ys: any,
  type: string,
  name: string,
  isDisabled: boolean | null,
  restrictionType: RestrictionTypeKeys,
): ContextMenuItem {
  return {
    name: name,
    disabled() {
      //not so necessary since we hide all disabled items!
      return false;
    },
    hidden() {
      if (isDisabled) {
        return true;
      }
      const hotInstance: HotTable["hotInstance"] = this;
      let col = hotInstance.getSelectedLast()[1];
      if (hotInstance.getSelectedLast()[1] === 0) {
        return true;
      }
      const { row } = hotInstance.getSelectedRangeLast().highlight;
      return row != 1;
    },
    callback: (itemName: any, selection: any) => {
      let col = selection[0].start.col;

      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(ys.getActiveHotel(), col, type);
          break;
        case "min_days" as RestrictionTypeKeys:
          ys.minDayForDay(col, type);
          break;
      }
    },
  };
}
