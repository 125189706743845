import { CellCoords, BackendEvent } from "../../../../models/yield.models";
import { YieldSheetService } from "../../../services/yield-sheet";
import moment from "moment";
let Queue = require("better-queue");
let MemoryStore = require("better-queue-memory");

const queueExecutor = (task: any, cb: (err: any) => void) => {
  task
    .fn(task.params)
    .then((_: any) => {
      cb(null);
    })
    .catch((err: any) => cb(err));
};

var queue: any;

const getQueue = function (onFinish: () => Promise<any>) {
  if (queue) {
    return queue;
  }
  queue = new Queue(queueExecutor, {
    store: new MemoryStore(),
    batchSize: 1,
    batchDelay: 500,
  });
  queue.on("drain", () => {
    queue = null;
    if (onFinish) {
      onFinish().catch((err: any) => console.log(err));
      return;
    }
  });
  return queue;
};

const updateEvent = function (
  event: BackendEvent,
  onFinish: () => Promise<any>,
) {
  getQueue(onFinish).push({
    fn: YieldSheetService.revanista.yieldsheet.days.updateCalendarEvents,
    params: event,
    onFinish: onFinish,
  });
};

const extendEvent = function (
  event: BackendEvent,
  endCoords: CellCoords,
  startcoords: CellCoords,
  onFinish: () => Promise<any>,
) {
  if (event) {
    event.end = moment(event.start)
      .add(Math.abs(startcoords.col - endCoords.col), "days")
      .format("YYYY-MM-DD");
    getQueue(onFinish).push({
      fn: YieldSheetService.revanista.yieldsheet.days.updateCalendarEvents,
      params: event,
    });
  }
};
const reduceEvent = function (
  event: BackendEvent,
  onFinish: () => Promise<any>,
) {
  if (event) {
    event.end = event.start;
    getQueue(onFinish).push({
      fn: YieldSheetService.revanista.yieldsheet.days.updateCalendarEvents,
      params: event,
    });
  }
};
const handleEventActions = function (
  hotelId: number,
  name: string,
  event: BackendEvent,
  onFinish: () => Promise<any>,
) {
  return new Promise((resolve, reject) => {
    let action = name.split(":")[0];
    switch (action) {
      case "notifygnh":
        if (event) {
          let e = {
            start: event.start,
            end: event.end,
            value: event.value,
          };
          YieldSheetService.revanista.yieldsheet.notify
            .event(hotelId, e)
            .then((_: any) => {
              resolve({});
            })
            .catch((err: any) => {
              reject(err);
            });
        }

        break;
    }
  });
};
export default {
  handleEventActions,
  updateEvent,
  reduceEvent,
  extendEvent,
};
