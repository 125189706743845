import { action, computed, decorate, observable } from "mobx";

class RequestsStore {
  _rootStore: any;

  // states: 'idle', 'fetching', 'success', 'error'
  _admin: any = {
    createHotelConfig: {
      state: "idle",
    },
    deleteHotelConfig: {
      state: "idle",
    },
    getHotelConfig: {
      state: "idle",
    },
    getUsersHotelsMeta: {
      state: "idle",
    },
    updateHotelConfig: {
      state: "idle",
    },
  };
  _app: any = {
    home: {
      state: "idle",
    },
  };
  // states: 'idle', 'fetching', 'success', 'error'
  _auth: any = {
    signin: {
      state: "idle",
    },
    signout: {
      state: "idle",
    },
    forgotPassword: {
      state: "idle",
    },
    requireNewPassword: {
      state: "idle",
    },
  };
  _yield: any = {
    notifyHotel: {
      state: "idle",
    },
    bulkUpdateRates: {
      state: "idle",
    },
    bulkUpdateRestrictions: {
      state: "idle",
    },
    list: {
      state: "idle",
    },
    publishAllRates: {
      state: "idle",
    },
    publishRatesInView: {
      state: "idle",
    },
    publishAllRestrictions: {
      state: "idle",
    },
    publishRestrictions: {
      state: "idle",
    },
    updateLeftToSell: {
      state: "idle",
    },
    updateSuggestedBaseRate: {
      state: "idle",
    },
    writeRestrictions: {
      state: "idle",
    },
  };

  constructor(rootStore: any) {
    this._rootStore = rootStore;
  }

  get admin() {
    try {
      return this._admin;
    } catch (err) {
      console.error(err);
    }
  }

  setAdmin(target: string, value: string) {
    try {
      this._admin[target]["state"] = value;
    } catch (err) {
      console.error(err);
    }
  }

  get app() {
    try {
      return this._app;
    } catch (err) {
      console.error(err);
    }
  }

  setApp(target: string, value: string) {
    try {
      this._app[target]["state"] = value;
    } catch (err) {
      console.error(err);
    }
  }

  get auth() {
    try {
      return this._auth;
    } catch (err) {
      console.error(err);
    }
  }

  setAuth(target: string, value: string) {
    try {
      this._auth[target]["state"] = value;
    } catch (err) {
      console.error(err);
    }
  }

  get yield() {
    try {
      return this._yield;
    } catch (err) {
      console.error(err);
    }
  }

  setYield(target: string, value: string) {
    try {
      this._yield[target]["state"] = value;
    } catch (err) {
      console.error(err);
    }
  }
}

decorate(RequestsStore, {
  _admin: observable,
  admin: computed,
  setAdmin: action,
  _app: observable,
  app: computed,
  setApp: action,
  _auth: observable,
  auth: computed,
  setAuth: action,
  _yield: observable,
  yield: computed,
  setYield: action,
});

export { RequestsStore };
