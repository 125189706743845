import { Auth } from "aws-amplify";
import { SignIn as AmplifySignIn } from "aws-amplify-react";
import { Field, Form, Formik, FormikProps, FormikActions } from "formik";
import { MouseEvent } from "react";
import * as Yup from "yup";
import { AuthState, IAuthData } from "../../models/auth.models";
import { getFieldClasses, resetFormError } from "../common/forms";
import handleEmailValidation from "../utils/emailValidator";

interface ISignInProps {
  authData: IAuthData;
  authState: AuthState;
  onAuthStateChange: (next: any, data: any) => void;
  onAuthEvent: (next: any, data: any) => void;
}

interface ISignInState {
  pending: boolean;
  success: boolean;
  delivery?: any;
}

const ResetSchema = Yup.object().shape({
  code: Yup.string().required(
    "Please provide the code received in the reset password email",
  ),
  password: Yup.string().required("Please provide the new password"),
});

class ResetPassword extends AmplifySignIn<ISignInProps, ISignInState> {
  static defaultProps: ISignInProps = {
    authData: {},
    authState: "signIn",
    onAuthStateChange: (next: any, data: any) => null,
    onAuthEvent: (next: any, data: any) => null,
  };

  static state = {
    pending: false,
    delivery: null,
    success: false,
  };

  _validAuthStates = ["resetPassword"];

  onSendRequest = (
    { username = "" }: IAuthData,
    actions: FormikActions<IAuthData>,
  ) => {
    Auth.forgotPassword(username.toLowerCase())
      .then((data) => {
        this.setState({ success: true });
      })
      .catch((err) => actions.setStatus({ error: err.message || err }));
  };

  onSubmitNew = (
    { code = "", password = "", username }: IAuthData,
    actions: FormikActions<IAuthData>,
  ) => {
    const { authData = {} } = this.props;
    username = username || authData.username;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }
    Auth.forgotPasswordSubmit(username!.toLowerCase(), code, password)
      .then((data) => {
        this.changeState("signIn");
        this.setState({ success: true, delivery: null });
      })
      .catch((err) => actions.setStatus({ error: err.message || err }));
  };

  checkContact(user: IAuthData) {
    Auth.verifiedContact(user).then(() => {
      this.changeState("signedIn", user);
    });
  }

  onBackToSignIn = (e: MouseEvent) => {
    e.preventDefault();
    this.changeState("signIn", null);
  };

  validateEmail(value: any) {
    let error;
    const validateAdmin = handleEmailValidation(value);
    if (!value) {
      error = "Please provide your email";
    } else if (validateAdmin == false) {
      error = "Please provide a correct email address";
    }
    return error;
  }

  showComponent() {
    const initialFormValues: IAuthData = {
      username: "",
      code: "",
      password: "",
    };

    return (
      <div className="row mt-4">
        <Formik
          initialValues={initialFormValues}
          onSubmit={this.state.success ? this.onSubmitNew : this.onSendRequest}
          validationSchema={this.state.delivery && ResetSchema}
        >
          {(formState) => (
            <Form
              onChange={resetFormError.bind(this, formState)}
              className="col-sm-4 offset-sm-4 mt-4"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="card">
                <fieldset
                  className="card-body p-5"
                  disabled={this.state.pending}
                >
                  <h1>Reset your password</h1>
                  {this.state.success && (
                    <p className="text-muted mb-4">
                      reset code has been sent to the provided email.
                    </p>
                  )}
                  {!this.state.success && (
                    <p className="text-muted mb-4">
                      As part of a system upgrade on Revanista, we need to
                      verify your email address and ask you to create a new
                      password.
                      <p>
                        Enter your email address below to receive a code via
                        email.
                      </p>
                    </p>
                  )}

                  {this.state.success
                    ? this.submitView(formState)
                    : this.sendView(formState)}

                  {formState.status && formState.status.error ? (
                    <p className="text-danger">{formState.status.error}</p>
                  ) : (
                    ""
                  )}

                  <button type="submit" className="btn btn-lg btn-primary mt-4">
                    {this.state.success ? "Reset password" : "Send code"}
                  </button>
                  <p className="text-left mt-2">
                    <a href="#" onClick={this.onBackToSignIn}>
                      Back to Sign In
                    </a>
                  </p>
                  <p className="text-muted mb-4">
                    If you encounter any issue, reach out to{" "}
                    <a href="mailto:support@revanista.com?subject=Revanista Reset Password">
                      support@revanista.com
                    </a>
                  </p>
                </fieldset>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  private sendView = (formState: FormikProps<IAuthData>) => (
    <div className="form-group">
      <label htmlFor="email" className="required">
        Email
      </label>
      <Field
        type="text"
        name="username"
        id="email"
        className={getFieldClasses<IAuthData>(formState, "username", "lg")}
        placeholder="Enter your email"
        validate={this.validateEmail}
      />
      <div className="invalid-feedback">{formState.errors.username}</div>
    </div>
  );

  private submitView = (formState: FormikProps<IAuthData>) => (
    <>
      <div className="form-group">
        Code:
        <Field
          autoComplete="off"
          type="text"
          name="code"
          className={getFieldClasses<IAuthData>(formState, "code", "lg")}
          placeholder="Code"
        />
        <div className="invalid-feedback">{formState.errors.code}</div>
      </div>

      <div className="form-group">
        New password :
        <Field
          autoComplete="off"
          type="password"
          name="password"
          className={getFieldClasses<IAuthData>(formState, "password", "lg")}
          //placeholder="New Password"
        />
        <div className="invalid-feedback">{formState.errors.password}</div>
      </div>
    </>
  );
}

export { ResetPassword as ResetPassword };
