import { Component, forwardRef, Fragment } from "react";
import MaterialTable, { Icons } from "material-table";
import { toast } from "react-toastify";
import {
  ArrowDownward,
  Search,
  Edit,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  AddBox,
  Clear,
  DeleteOutline,
} from "@material-ui/icons";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export interface ICompSetUpdateprops {
  items: any;
  originalItems: any;
  hotelCompSet: any;
  parentCallback: (rp: any) => void;
}

interface State {
  data: any;
  originalData: any;
  changesCanceled: boolean;
  hotelCompSet: any;
  selectCompSet: any;
  tempArr: any;
  reloadTable: any;
  switchList: [];
}

class CompSetsTable extends Component<ICompSetUpdateprops> {
  readonly state: State = {
    data: [],
    originalData: this.props.originalItems,
    changesCanceled: false,
    hotelCompSet: [],
    selectCompSet: [],
    tempArr: [],
    reloadTable: [],
    switchList: [],
  };

  constructor(props: any) {
    super(props);
    this.state.data = props.items;
    this.state.selectCompSet = [];
    this.state.tempArr = this.props.hotelCompSet;

    this.state.reloadTable = props.items;
  }

  // logic to add and remove element from the hotel compset or checkbox based on check & uncheck status
  handleOnClick = (event: any) => {
    let data = [];
    let checked = event.target.checked;
    let value = event.target.value || event.target.checked;
    console.log(this.state.tempArr, "this.state.tempArr");

    if (checked === true) {
      this.state.tempArr.indexOf(parseInt(value)) === -1
        ? this.state.tempArr.push(parseInt(value))
        : this.state.tempArr;
      data = this.state.data.map(function (val: any) {
        if (val.id == value) {
          val["Active"] = true;
        }
        return val;
      });
    }
    if (checked === false) {
      if (this.state.tempArr.length < 2) {
        event.target.checked = true;
        toast.error("Please select at least one hotel");
        return value;
      } else {
        this.state.tempArr.splice(
          this.state.tempArr.indexOf(parseInt(value)),
          1,
        );
        data = this.state.data.map(function (val: any) {
          if (val.id == value) {
            val["Active"] = false;
          }
          return val;
        });
      }
    }
    this.setState({ data: data });
    this.props.parentCallback(this.state.tempArr);
  };
  render() {
    return (
      <Fragment>
        <MaterialTable
          columns={[
            {
              title: "S.No",
              field: "serialNo",
              cellStyle: {
                paddingRight: "30px",
                textAlign: "left",
                width: "10%",
                fontSize: "16px",
              },
              headerStyle: {
                textAlign: "left",
                fontSize: "20px",
              },
            },
            {
              title: "Hotel Name",
              field: "name",
              cellStyle: {
                textAlign: "left",
                width: "50%",
                fontSize: "16px",
              },
              headerStyle: {
                textAlign: "left",
                fontSize: "20px",
              },
            },
            {
              title: "Show",
              field: "Action",
              cellStyle: {
                textAlign: "left",
                width: "20%",
                fontSize: "16px",
              },
              headerStyle: {
                fontSize: "20px",
                textAlign: "left",
              },
              editComponent: (props: any) => {
                return <input type="checkbox" />;
              },
              render: (data: any) => (
                <input
                  type="checkbox"
                  defaultChecked={data.Active}
                  value={data.id}
                  onChange={this.handleOnClick}
                />
              ),
            },
            {
              title: "Updated Date",
              field: "updatedDate",
              cellStyle: {
                textAlign: "left",
                width: "30%",
                fontSize: "16px",
              },
              headerStyle: {
                fontSize: "20px",
                textAlign: "left",
              },
            },
          ]}
          data={this.state.data}
          options={{
            search: false,
            toolbar: false,
            paging: false,
            draggable: false,
          }}
          icons={tableIcons}
        />
      </Fragment>
    );
  }
}
export default CompSetsTable;
