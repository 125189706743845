import validator from "validator";

export default function handleEmailValidation(email: any) {
  const split = email.split("@");
  const last = split.pop();
  const topLevelDomain = last.slice(0, -4);
  const emailValidator = validator.isEmail(email, {
    allow_utf8_local_part: false,
    host_blacklist: [`${topLevelDomain}.web`],
  });

  return emailValidator;
}
