import { IYieldDay } from "../../models/yield.models";
import { Rowprop, rowType } from "../yield-sheet/rowProp";
import { CellValue } from "react-table";
import { DateTime } from "luxon";
import moment from "moment";
import { YieldSheetService } from "../services";
import { YieldApi } from "../../api/yield";
import { OtaApi } from "../../api/ota";
import { appStore } from "../store";
import { toast } from "react-toastify";

class SheetView {
  sheetHotel: any;
  rowProps: Rowprop;
  days: IYieldDay[];
  startDate: DateTime;
  endDate: DateTime;
  sheetData: any;
  compset: any[];
  compareOtaRate: any[];
  compareDemand: any[];

  constructor(
    sheetHotel: any,
    rowProps: Rowprop,
    startDate: DateTime,
    endDate: DateTime,
  ) {
    this.sheetHotel = sheetHotel;
    this.startDate = startDate;
    this.endDate = endDate;
    this.days = [];
    this.compset = [];
    this.compareOtaRate = [];
    this.compareDemand = [];
    this.rowProps = rowProps;
  }

  async resfreshData() {
    try {
      const backendData = await this.fetchFromBackend();
      this.days = backendData;

      const otaHotelId = parseInt(appStore.activeHotel.ota.hotelId);
      const hotelId = parseInt(appStore.activeHotel.hotelId);
      const pickupFrom = this.rowProps.pickupFrom;
      const marketDemandPickup = this.rowProps.marketDemand;
      const start = moment(this.startDate.toJSDate()).format("YYYY-MM-DD");
      const end = moment(this.endDate.toJSDate()).format("YYYY-MM-DD");

      let compSetArray = [];
      let displayDemandData = [];

      const compData = await OtaApi.getRatesFromOTATable(
        otaHotelId,
        start,
        end,
      );
      // compSetArray = Object.values(compData);
      compSetArray = compData;

      const demandData = await OtaApi.getDemandFromOTATable(
        otaHotelId,
        start,
        end,
      );
      displayDemandData = demandData;

      console.log("demand data", demandData);

      const otaCompareData = await OtaApi.compareOTADataFromPickupData(
        otaHotelId,
        moment(marketDemandPickup).format("YYYY-MM-DD"),
        start,
        end,
      );

      if (start < moment().add(1, "year").format("YYYY-MM-DD")) {
        const data = otaCompareData;

        const otaDemandCompare = data["otaDemandCompare"];
        const otaRatesCompare = data["otaRateCompare"];

        let allEmpty = [];

        for (const timestamp in otaDemandCompare) {
          if (
            Object.keys(otaDemandCompare[timestamp]).length === 0 &&
            Object.keys(otaRatesCompare[timestamp]).length === 0
          ) {
            allEmpty.push("empty");

            if (allEmpty.length === Object.keys(otaDemandCompare).length) {
              toast.warn(
                "No compset data available for the selected date. Please select another date.",
              );
            }
          }
        }
      }

      let displaypickupData = {};
      if (appStore.activeHotel.cm.id == "guestline") {
        try {
          displaypickupData = await YieldApi.getPickupData(
            hotelId,
            pickupFrom.valueOf(),
          );
        } catch (error) {
          console.error("Error fetching demand:", error);
        }
      }

      this.sheetData = this.prepareData(
        backendData,
        this.sheetHotel,
        compSetArray,
        displayDemandData,
        displaypickupData,
        otaCompareData,
      );
      return this.sheetData;
    } catch (error) {
      console.error("Error:", error);
      // Handle the error appropriately
      throw error; // Rethrow the error to be caught by the caller if necessary
    }
  }

  fetchFromBackend(): Promise<IYieldDay[]> {
    return new Promise((resolve, reject) => {
      let start = this.startDate;
      let end = this.endDate;

      let promises: Promise<any>[] = [];
      promises.push(
        YieldSheetService.revanista.yieldsheet.days.list(
          this.sheetHotel.hotelId,
          start.toISODate(),
          end.toISODate(),
        ),
      );

      Promise.all(promises)
        .then((payloads) => {
          let merged = [];
          for (let payload of payloads) {
            merged.push(...payload);
          }
          merged.sort((day1, day2) => {
            return day1.dateMidday - day2.dateMidday;
          });
          resolve(merged);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  notifyHotel() {
    return new Promise((resolve, reject) => {
      YieldSheetService.revanista.yieldsheet.notify
        .suggestedBR(this.sheetHotel.hotelId)
        .then((result: any) => {
          resolve({ count: result.suggestedCount });
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  prepareData(
    days: IYieldDay[],
    hotel: any,
    compSetArray: any,
    displayDemandData: any,
    displayPickupData: any,
    otaCompareData: any,
  ) {
    let data: CellValue[][];
    data = [...new Array(this.rowProps.lastRowIndex + 1)].map(() => []);

    for (let i = 0; i < data.length; i++) {
      data[i] = data[i].slice(0, days.length);
    }
    let startIdx = 0;
    console.log("displayDemandData", displayDemandData);
    for (var dayIdx = 0, len = days.length; dayIdx < len; dayIdx++) {
      let otaupdateDate = "-";
      let sheetDay = days[dayIdx];
      let demandData = displayDemandData[days[dayIdx].dateMidday];

      let compSetData = compSetArray[days[dayIdx].dateMidday];

      let compareRateOfOta =
        otaCompareData["otaRateCompare"][days[dayIdx].dateMidday];
      let compareDemandOfOta =
        otaCompareData["otaDemandCompare"][days[dayIdx].dateMidday];

      console.log(
        compareRateOfOta,
        compSetData,
        compareDemandOfOta,
        demandData,
      );

      const col = startIdx + dayIdx + 1;
      const m = moment(sheetDay.dateMidday).valueOf();
      for (let i = 0; i <= this.rowProps.lastRowIndex; i++) {
        data[i][0] = this.rowProps.caption(i);

        var v;
        let rp = this.rowProps.get(i);
        if (!rp) {
          continue;
        }

        let totalLeftToSell = 0;
        for (let key in sheetDay.availabilities) {
          if (key != "stat") {
            totalLeftToSell =
              totalLeftToSell + Number(sheetDay.availabilities[key].leftToSell);
          }
        }

        switch (rp.type) {
          case rowType.leftToSellUpdate:
            v = "";
            break;

          case rowType.derivedRateFiller:
            v = "";
            break;
          case rowType.suggestedBaseRate:
            console.log("sheetDay ======", sheetDay.suggestedBaseRate);
            v =
              sheetDay && sheetDay.suggestedBaseRate
                ? sheetDay.suggestedBaseRate
                : "";
            // sheetDay.suggestedBaseRate == undefined
            //   ? ""
            //   : sheetDay.suggestedBaseRate; /* condition if same as base rate then don't show SBR */

            break;
          case rowType.autoSuggested:
            v = hotel.computeSuggestion(sheetDay);
            console.log("auto", v);

            break;
          case rowType.actualBaseRate:
            v = sheetDay.actualBaseRate;
            break;
          case rowType.actualOWSBaseRate:
            v = sheetDay.actualOWSBaseRate;
            break;
          case rowType.leftToSell:
            if (hotel.cm.id == "guestline") {
              if (typeof totalLeftToSell == "undefined") {
                v = "-";
              } else {
                v = totalLeftToSell;
              }
            } else {
              // Refers to the Left to sell updates row
              v = sheetDay.availability.leftToSell;
            }
            break;
          case rowType.occupancy:
            v =
              Math.round(
                ((hotel.meta.totalRooms - totalLeftToSell) /
                  hotel.meta.totalRooms) *
                  100,
              ) + " %";
            break;
          case rowType.ratePlan:
            if (rp.isManual) {
              v =
                sheetDay.manualRates && sheetDay.manualRates[rp.roomTypeId]
                  ? sheetDay.manualRates[rp.roomTypeId]![rp.ratePlanId]
                    ? sheetDay.manualRates[rp.roomTypeId]![rp.ratePlanId].rate
                    : "-"
                  : "-";
            } else {
              v = sheetDay.derivedRates[rp.roomTypeId]
                ? sheetDay.derivedRates[rp.roomTypeId]![rp.ratePlanId]
                  ? sheetDay.derivedRates[rp.roomTypeId]![rp.ratePlanId].rate
                  : "-"
                : "-";
            }
            if (v !== "-") {
              v = Number(v) / 100;
              if (v % 1 > 0) {
                v = v.toFixed(2);
              }
            }
            break;
          case rowType.roomType:
            if (
              appStore.activeHotel.meta.isPublishingInventory === "false" &&
              appStore.activeHotel.cm.id !== "guestline"
            ) {
              let currency = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: appStore.activeHotel.info.currencyCode,
              });
              let result = "-";
              if (
                typeof sheetDay.availabilities[rp.roomTypeId] !== "undefined"
              ) {
                let formula = hotel.roomTypes[rp.roomTypeId].formula;
                let BR = sheetDay.actualBaseRate;
                formula = formula.replace("BR", BR);
                let evaluatedFormula = eval(formula);
                result = currency.format(evaluatedFormula);
              }

              v = result;
            } else {
              v =
                typeof sheetDay.availabilities[rp.roomTypeId] != "undefined"
                  ? sheetDay.availabilities[rp.roomTypeId].leftToSell
                  : "-";
            }
            break;
          case rowType.rewards:
            v = sheetDay.rewards ? "yes" : "no";
            break;
          case rowType.pickup:
            v = sheetDay.availability.lastUpdateLeftTosell
              ? sheetDay.availability.lastUpdateLeftTosell -
                sheetDay.availability.leftToSell
              : "";
            break;
          case rowType.pickupFrom:
            let sheetDayLeftToSell = 0;
            for (let key in sheetDay.availabilities) {
              if (key != "stat") {
                console.log(sheetDay.availabilities[key].leftToSell);
                sheetDayLeftToSell =
                  sheetDayLeftToSell +
                  Number(sheetDay.availabilities[key].leftToSell);
              }
            }

            if (typeof displayPickupData[m.toString()] == "undefined") {
              v = "-";
            } else {
              v = displayPickupData[m.toString()] - sheetDayLeftToSell;
            }
            break;
          case rowType.pickupDate:
            v = sheetDay.availability.pickupDate
              ? moment(sheetDay.availability.pickupDate).format("MMM Do")
              : "";
            break;
          case rowType.lastUpdateLeftTosell:
            v = sheetDay.availability.lastUpdateLeftTosell
              ? sheetDay.availability.lastUpdateLeftTosell
              : "";
            break;
          case rowType.marketDemand:
            v =
              demandData && demandData["demand"] != undefined
                ? Math.round(Number(demandData["demand"]) * 100) + " %"
                : "-";

            // compare demand value

            let compareDemand =
              compareDemandOfOta && compareDemandOfOta["demand"] != undefined
                ? compareDemandOfOta["demand"]
                : "-";

            this.compareDemand[col] = this.compareDemand[col]
              ? this.compareDemand[col]
              : {};
            this.compareDemand[col] = compareDemand;
            // this.compareDemand = compareDemand
            console.log("col", col, this.compareDemand[col]);
            break;
          case rowType.ota:
            // let r = sheetDay.ota.rates[rp.hotelName];
            let r =
              compSetData !== undefined ? compSetData[rp.hotelName] : undefined;
            this.compset[col] = this.compset[col] ? this.compset[col] : {};
            this.compset[col][rp.hotelName] = r;
            v = this.compset[col][rp.hotelName];

            console.log("rates", r, v);

            // ota pickup value

            let compareRate =
              compareRateOfOta && compareRateOfOta !== undefined
                ? compareRateOfOta[rp.hotelName]
                : undefined;
            this.compareOtaRate[col] = this.compareOtaRate[col]
              ? this.compareOtaRate[col]
              : {};
            this.compareOtaRate[col][rp.hotelName] = compareRate;

            // this.compareOtaRate = compareRate;

            console.log("col", col, this.compareOtaRate[col]);
            if (otaupdateDate == "-") {
              otaupdateDate = r && r.updatedDate ? r.updatedDate : "-";
            } else if (r && r.updatedDate) {
              if (moment(r.updatedDate).isAfter(otaupdateDate)) {
                otaupdateDate = r.updatedDate;
              }
            }

            break;
          case rowType.lastUpdateOta:
            if (otaupdateDate == "-") {
              v = "-";
            } else {
              v = moment(otaupdateDate).format("DD-MM-YYYY");
            }
            break;
        }
        data[i][col] = v;
      }
    }

    return data;
  }
}
export default SheetView;
